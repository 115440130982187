import React, { useState ,useEffect} from 'react';
import { message, Spin } from 'antd';

const Financials = ({ setFinancials,setUploader, setGenerateVideo, handleDataUpdate ,initialData}) => {
    const [taxInfo, setTaxInfo] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (initialData) {
            setTaxInfo(initialData.taxInfo || '');
        }
    }, [initialData]);

    const validateFields = () => {
        return taxInfo
    };

    const Next = () => {
        if (!validateFields()) {
            message.error('Please Provide all fields.');
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setFinancials(true);
            setUploader(false);
        }, 1000)
        const formData = {
            taxInfo,
        };
        handleDataUpdate(formData);
    };

    return (
        <div className="createVideomainDiv stepFiveDiv">
            <div className="essentialInnerDiv stepFiveInnerDiv">
                <div className="stepsCount">
                    <span>step 05 / 07</span>
                </div>
                <h1 className='infoHeading'>Cover the Fine Print—Help Buyers Understand the Financials.</h1>
                <div className="createVideoSectionIneerDiv">

                    <div className='inputsDiv'>
                        <div className="mb-6">
                            <label htmlFor="tax-info" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Tax Information</label>
                            <input
                                type="text"
                                id="tax-info"
                                placeholder="Property Tax $5,000/year"
                                value={taxInfo}
                                onChange={(e) => setTaxInfo(e.target.value)}
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                            />
                        </div>
                    </div>
                </div>


                <div className="nextButton" onClick={Next}>
                    {loading ? <Spin  className="custom-spin" /> : 'Next'}
                </div>
            </div>
        </div>
    );
};

export default Financials;
