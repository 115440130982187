import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import userReducer from './reducers/usersReducer'
const rootReducer = combineReducers({
    auth: authReducer, 
    userReducer:userReducer
    
});

export default rootReducer;
