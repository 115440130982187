import React from "react";
import Slider from "react-slick";


function SimpleSlider() {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            <Slider {...settings}>
                <div>
                    <p className="slider_text"><i>"Working with Block Consult has been an absolute pleasure. We learnt a lot about making our internal processess more efficient and how to improve the projects presentation towards external stakeholders.These comprehensive solutions are especially helping Tokapi during our funding rounds.We're looking forward to working together again!"</i></p>
                    <div className="slider_img_and_name_div_main">
                        <div className="slider_img_and_name_div">
                            <div>
                                <p className="slider_name">Daniel Lenikus</p>
                                <p className="slider_name">CEO at Tokapi</p>
                            </div>
                        </div>
                    </div>
                </div>
         
            </Slider>
        </div>
    );
}

export default SimpleSlider;
