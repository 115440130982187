import HomeOutlined from '@ant-design/icons/HomeOutlined'
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined'
import LayoutOutlined from '@ant-design/icons/LayoutOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { TbFolderOpen } from "react-icons/tb";
import { LuLayoutDashboard } from "react-icons/lu";
import { TbLayoutDashboard } from "react-icons/tb";
import allPaths from '../Config/paths'
import Home from '../Screens/Home/Home'
import { Login } from '../Screens';
import { vella ,cozy ,modern ,Plusicon ,projectsImg} from '../Assets';
const bgColor = '#0adc00'

const drawerRoutes = [
    {
        title: 'Home',
        route: allPaths.HOME,
        icon: <HomeOutlined  />
    },
    {
        title: 'Projects',
        route: allPaths.PROJECT,
        icon: <FolderOpenOutlined   />
    },
    {
        title: 'Templates',
        route: allPaths.TEMPLATES,
        icon: <LayoutOutlined   />
    },
    {
        title: 'Setting',
        route: allPaths.SETTINGS,
        icon: <SettingOutlined  />
    }
]
const RecentVideos = [
    {
        img: vella,
        heading: 'Luxury Villa in Malibu',
        paragraph: 'A stunning 5-bedroom, 4-bath villa with a contemporary design...'
    },
    {
        img: cozy,
        heading: 'Cozy Family Home in Napa Valley',
        paragraph: 'Charming 3-bedroom, 2-bath home with a newly remodeled kitchen..'
    },
    {
        img: modern,
        heading: 'Modern Condo in Beverly Hills',
        paragraph: 'Floor-to-ceiling windows, beachfront access, modern minimalist design'
    }
]
const VideoOptions = [
    {
        icon: Plusicon,
        heading: 'Custom video',
        paragraph: 'Build Your Property’s Story from Scratch.',
    },
    {
        icon: <LuLayoutDashboard  size={30}  color='#858585' />,
        heading: 'Choose from Templates',
        paragraph: 'Quick and Easy - Pick a Ready-Made Template.',
    },
];
const GenerateVideoOptions = [
    {
        size: '16:9',
        heading: 'Landscape',
        paragraph: 'Best for Websites & YouTube',
    },
    {
        size: '1:1',
        heading: 'Square',
        paragraph: 'Perfect for Instagram & Facebook',
    },
    {
        size: '9:16',
        heading: 'Vertical',
        paragraph: 'Optimized for Mobile Viewing',
    },
];

const UploaderOptions = [
    {
        heading: 'Image Uploader',
        paragraph: 'Upload Your Video to Insert in video',
    },
    // {
    //     heading: 'Video Uploader',
    //     paragraph: 'Upload Your  Video to Insert in video',
    // },
  
]
const WhyChooseUs = [
    {
        countHeading:"ONE",
        sideHeading:"AI-Powered Video Creation",
        paragraph:"Effortlessly create engaging videos by simply typing in your ideas. Our advanced AI technology transforms natural language prompts into fully realized videos, complete with visuals, animations, and text, saving you time and effort while maintaining creative control."
    },
    {
        countHeading:"TWO",
        sideHeading:"Customizable Templates",
        paragraph:"Choose from a wide range of professionally designed templates tailored to various needs, such as marketing ads, educational content, and social media posts. Easily customize layouts, text, and visuals to match your specific goals and style, ensuring a polished look every time."
    },
    {
        countHeading:"THREE",
        sideHeading:"Brand Customization",
        paragraph:"Seamlessly integrate your brand's identity by adding custom logos, fonts, and color schemes to your videos. Consistently reinforce your brand’s visual language across all your video content, helping to maintain brand recognition and coherence."
    },
    {
        countHeading:"FOUR",
        sideHeading:"High-Quality Output",
        paragraph:"Deliver top-notch visual experiences with export options up to 4K resolution. Whether you're creating videos for large screens, professional presentations, or social media, our tool ensures your content is clear, detailed, and visually stunning, leaving a lasting impression on your audience."
    }
]

const googleClientId = '990473638043-jdvlhsmg3qrqchinre7c1vsgpqrkh0fv.apps.googleusercontent.com'


export {
    googleClientId,
    bgColor,
    drawerRoutes,
    RecentVideos,
    VideoOptions,
    GenerateVideoOptions,
    WhyChooseUs,
    UploaderOptions
    // allPaths,
}

