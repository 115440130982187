import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, SideMenu } from '../../Components';
import axios from 'axios';
import { GET } from '../../utils/apis';
import ReactPlayer from 'react-player';
import { RecentVideos } from '../../utils/constants';
import { Spin, Empty } from 'antd';

const Home = (props) => {
    const [error, setError] = useState(null);
    const userData = useSelector((state) => state.auth.loginData);
    const [loading, setLoading] = useState(false);
    const [authenticatedVideoUrls, setAuthenticatedVideoUrls] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(GET.GET_ALL_USERS);
                const users = response.data.data;
                const currentUser = users?.find(user => user.uid === userData.uid);
                if (currentUser) {
                    setAuthenticatedVideoUrls(currentUser?.authenticatedVideoUrls || []);
                }
            } catch (err) {
                console.log(err);
                setError('Failed to load users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [userData.uid]);

    return (
        <div className="RightSection">
            <div className='BrowsetemplatesDiv'>
                <div className="browseTemplateInnerText">
                    <h1>Choose Your Style, Craft Your Story—Pick a Template to Make Your Listing Shine</h1>
                    <div className="browesTemplateButton">
                        browse templates
                    </div>
                </div>
            </div>
            <div className="recentVideos">
                <div className="recentVideosInnerDiv">
                    <h1>Recent Videos</h1>
                    <div className="videosSection">
                        <div className="InnerSection">
                            {loading ? (
                                <div className="centered">
                                    <Spin />
                                </div>) : authenticatedVideoUrls.length > 0 ? (
                                    authenticatedVideoUrls.map((url, index) => (
                                        <div className='videoMainSection'>
                                            <div key={index} className="video">
                                                <ReactPlayer
                                                    width='100%'    
                                                    className='Rendervideo'
                                                    height='100%'
                                                    url={url?.url}
                                                    controls={true}
                                                    playing={false}
                                                    onError={() => console.log(`Error loading video at ${url}`)}
                                                />

                                            </div>
                                            <p className='paragraphFordetails'>This beautiful property located at {url?.address}</p>
                                        </div>
                                    ))
                                ) : (
                                <div className='emptydata'>
                                    <Empty description={'No videos generated'} />
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
