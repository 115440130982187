import React, { useState } from 'react';
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import { message, Button, Spin } from 'antd';
import axios from 'axios';
import { Footer, MenuDropDown } from "../../Components";
import logo from '../../Assets/Image/logo.png';
import paths from "../../Config/paths";
import { POST } from '../../utils/apis';

const ContactUs = () => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = { name, email, description };

        try {
            const response = await axios.post(POST?.CONTACT_US, formData);

            if (response.status === 200) {
                message.success('Your form has been successfully submitted!');
                setTimeout(() => {
                    history.push(paths?.LANDING_PAGE);
                }, 2000);
            } else {
                message.error('Form submission failed.');
            }
        } catch (error) {
            message.error(error.response?.data?.error || 'An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className='SignUpHeader logoHeaderDiv'>
                <div className="logoVideoAi mobileheaderLogo">
                    <img className='logo' src={logo} alt="Logo" />
                </div>
                <MenuDropDown />
            </div>

            <div className="go-back-icon">
                <span onClick={() => history.push(paths?.LANDING_PAGE)} className="back-arrow">
                    <ArrowBack />
                </span>
            </div>

            <div className="contact-main-div">
                <div className="contact-form-container">
                    <h1 className="contact-heading">Contact Us</h1>
                    <form className="contact-form" onSubmit={onSubmit}>
                        <div className="input-field">
                            <label htmlFor="name" className="label">Name</label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Enter your name"
                                className="contact-input-field"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="input-field">
                            <label htmlFor="email" className="label">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                className="contact-input-field"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div className="input-field">
                            <label htmlFor="description" className="label">Description</label>
                            <textarea
                                id="description"
                                placeholder="Describe your query or message"
                                rows="4"
                                className="contact-input-field"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                        </div>

                        <div className="submit-btn-container">
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                loading={loading} 
                                className="submit-btn"
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ContactUs;
