
import apiUrl from "../Config/api"

const authApi = `${apiUrl}/api/auth`
const getApi = `/api/get`
const postApi = `${apiUrl}/api/post`

const AUTH = {
    LOGIN: `${authApi}/login`,
    SIGNUP: `${authApi}/signup`,
    CHANGE_PASSWORD:`${authApi}/change-password`,
    FORGOT_PASSWORD:`${authApi}/forgot-password`,
    GOOGLELOGIN:`${authApi}/googleLogin`,
}
const GET = {
    GET_ALL_USERS: `${getApi}/all-users`,
}

const POST = {
    CREATEVIDEO:`${postApi}/createVideo`,
    CHANGENAME:`${postApi}/changeName`,
    DELETEURL:`${postApi}/deleteUrl`,
    UPLOAD:`${postApi}/upload`,
    PDFUPLOAD:`${postApi}/upload-pdf`,
    SAVEVIDEO:`${postApi}/saveUrl`,
    CONTACT_US:`${postApi}/contact-us`

}


export {
    AUTH,
    GET,
    POST
}