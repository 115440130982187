import React, { useState, useEffect } from 'react';
import { useHistory,Redirect } from 'react-router-dom';
import Header from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';
import MobileMenu from '../MobileMenu/MobileMenu';
import { useSelector, useDispatch } from 'react-redux'
import paths from '../../Config/paths';
const MenuLayout = ({ component: Component, ...props }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
    const userData = useSelector((state) => state?.auth?.loginData);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    if (!userData) {
        return (
            <Redirect to={paths?.LOGIN} />
        )
    }
    return (
        <div className='helper-main'>
            {!isMobile && <Header />}
            <div >
                {isMobile ? (
                    <>
                        <div className='mobile-menu-flex'>
                            <MobileMenu />
                        </div>
                        <div className='mobile-helper-comp'>
                            <Component {...props} />
                        </div>
                    </>

                ) : (
                    <div className='desktop-menu-flex'>
                        <div className='sidebarSection'>
                            <SideMenu {...props} />
                        </div>
                        <div className='helper-comp'>
                            <Component {...props} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export { MenuLayout };
