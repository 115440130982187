const usersReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ALL_USERS': {
            return { ...state, allUsers: action.users }
        }
        default: {
            return state
        }
    }
}
export default usersReducer