import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';

const Neighborhood = ({ setNeighborhood, setFinancials, handleDataUpdate, initialData }) => {
    const [schoolDistrict, setSchoolDistrict] = useState('');
    const [hoaDetails, setHoaDetails] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (initialData) {
            setSchoolDistrict(initialData.schoolDistrict || '');
            setHoaDetails(initialData.hoaDetails || '');
        }
    }, [initialData]);

    const validateFields = () => {
        return schoolDistrict && hoaDetails;
    };

    const Next = () => {
        if (!validateFields()) {
            message.error('Please provide all fields.');
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setNeighborhood(true);
            setFinancials(false);
        }, 1000)
        const formData = {
            schoolDistrict,
            hoaDetails
        };
        handleDataUpdate(formData);
    };

    return (
        <div className="createVideomainDiv stepFiveDiv">
            <div className="essentialInnerDiv stepFiveInnerDiv">
                <div className="stepsCount">
                    <span>step 04 / 07</span>
                </div>
                <h1 className='infoHeading'>Location Matters—Give Buyers a Glimpse of the Neighborhood.</h1>
                <div className="createVideoSectionIneerDiv">
                    <div className='inputsDiv'>
                        <div className="mb-6 marginInput">
                            <label htmlFor="school-district" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">School District</label>
                            <input
                                type="text"
                                id="school-district"
                                placeholder="LA Unified"
                                value={schoolDistrict}
                                onChange={(e) => setSchoolDistrict(e.target.value)}
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                            />
                        </div>
                    </div>
                    <div className='inputsDiv'>
                        <div className="mb-6">
                            <label htmlFor="hoa-details" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">HOA Details</label>
                            <input
                                type="text"
                                id="hoa-details"
                                placeholder="$300/month"
                                value={hoaDetails}
                                onChange={(e) => setHoaDetails(e.target.value)}
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                            />
                        </div>
                    </div>
                </div>
                <div className="nextButton" onClick={Next}>
                    {loading ? <Spin className="custom-spin" /> : 'Next'}
                </div>
            </div>
        </div>
    );
};

export default Neighborhood;
