import React, { useState } from 'react';

const Faqs = () => {
    const [openAccordion, setOpenAccordion] = useState(null);

    const faqData = [
        {
            question: "What is RealVisionAI, and how does it work?",
            answer: "RealVisionAI is an AI-powered video generation tool designed for real estate agents. It allows users to quickly create professional property showcase videos by selecting property details and amenities. The AI then assembles a high-quality video tailored to the property's unique features."
        },
        {
            question: "How long does it take to create a video?",
            answer: "Once the property details and amenities are selected, RealVisionAI can generate a video within minutes. Simply upload any necessary information, and our AI will handle the rest."
        },
        {
            question: "What amenities can be highlighted in a RealVisionAI video?",
            answer: "RealVisionAI videos can feature a variety of amenities, such as pools, fireplaces, outdoor kitchens, walk-in closets, smart home features, and more. You can select the most relevant amenities to highlight in each video."
        },
        {
            question: "Can I make edits to a video after it's created?",
            answer: "Yes, RealVisionAI offers easy-to-use editing options. You can adjust text overlays, rearrange scenes, and make minor adjustments directly within the platform."
        },
        {
            question: "Are RealVisionAI videos compatible with social media and listing sites?",
            answer: "Absolutely. RealVisionAI videos are optimized for popular social media platforms, MLS listings, and real estate websites, making them easy to share wherever your clients are."
        },
        {
            question: "What does it cost to use RealVisionAI?",
            answer: "RealVisionAI offers flexible subscription plans tailored to your needs, with options for both single-use videos and monthly subscriptions. Contact us to learn more about pricing details."
        },
        {
            question: "How do I get started with RealVisionAI?",
            answer: "Getting started is simple! Just sign up, choose your plan, and begin creating videos by selecting your property details and amenities. Our onboarding process guides you through each step."
        }
    ];

    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };

    return (
        <div className="Reviews faqs">
            <div className="ToolsInnerDiv">
                <h1>Frequently Asked Questions</h1>
                <p>Get answers to common questions about RealVisionAI and how it can help streamline your real estate video creation process.</p>
            </div>

            <div id="accordion-collapse" className="w-3/4 mx-auto bg-white rounded-[20px] margin">
                {faqData.map((faq, index) => (
                    <React.Fragment key={index}>
                        <h2 id={`accordion-collapse-heading-${index}`}>
                            <button
                                type="button"
                                className="flex items-center justify-between w-full p-5 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 gap-3"
                                onClick={() => toggleAccordion(index)}
                                aria-expanded={openAccordion === index}
                                aria-controls={`accordion-collapse-body-${index}`}
                            >
                                <span>{faq.question}</span>
                                <span className="text-2xl openCloseIcon">{openAccordion === index ? '-' : '+'}</span>
                            </button>
                        </h2>
                        <div
                            id={`accordion-collapse-body-${index}`}
                            className={`${openAccordion === index ? 'block' : 'hidden'}`}
                            aria-labelledby={`accordion-collapse-heading-${index}`}
                        >
                            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                <p className="mb-2 text-gray-500 dark:text-gray-400">
                                    {faq.answer}
                                </p>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Faqs;
