import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import ProfileSetting from './ProfileSetting';
import Security from './Security';
const Settings = ({ prop }) => {
    const { TabPane } = Tabs;


    return (
        <div className='Prifletabs'>
            <h1 className='ProfileSettingheading'>Profile Settings</h1>
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Profile" key="1">
                    <ProfileSetting />
                </TabPane>
                <TabPane tab="Security" key="2">
                    <Security />
                </TabPane>

            </Tabs>
        </div>
    )
}

export default Settings;
