import axios from 'axios';
import ReactPlayer from 'react-player';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GET } from '../../utils/apis';
import { Spin, Empty } from 'antd';
import paths from '../../Config/paths';

const Projects = () => {
  const history = useHistory();
  const userData = useSelector((state) => state.auth.loginData);
  const [loading, setLoading] = useState(false);
  const [authenticatedVideoUrls, setAuthenticatedVideoUrls] = useState([]);
  const [address, setAddress] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GET?.GET_ALL_USERS);
        const users = response.data.data;
        const currentUser = users?.find(user => user?.uid === userData?.uid);
        if (currentUser) {
          setAuthenticatedVideoUrls(currentUser?.authenticatedVideoUrls || []);
          setAddress(currentUser?.videoAddres || '');
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userData.uid]);

  return (
    <div className="recentVideos">
      <div className='NewProjectButton' onClick={() => history.push(paths?.CREATE_VIDEO)}>
        <button>New Project</button>
      </div>
      <div className="recentVideosInnerDiv">
        <h1>Recent Projects</h1>
        <div className="videosSection">
          <div className="InnerSection">
            {loading ? (
              <div className="centered">
                <Spin />
              </div>
            ) : authenticatedVideoUrls.length > 0 ? (
              authenticatedVideoUrls.map((url, index) => (
                <div className='videoMainSection'>
                <div key={index} className="video">
                  <ReactPlayer
                    width='100%'
                    className='Rendervideo'
                    height='100%'
                    url={url?.url}
                    controls={true}
                    playing={false}
                    onError={() => console.log(`Error loading video at ${url}`)}
                  />
                
                </div>
                <p className='paragraphFordetails'>This beautiful property located at {url?.address}</p>
                </div>
              ))
            ) : (
              // <span>No videos generated</span>
              <div className='emptydata'>
                <Empty description={'No videos generated'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
