import React, { useState, useEffect } from 'react'
import { Reviews, Faqs, Footer, MenuDropDown } from '../../Components'
import { VideoImg, multimedia, cut, arrow, RotateIcon, Tool, AIicon } from '../../Assets'
import { WhyChooseUs } from '../../utils/constants'
import { GoArrowBoth } from "react-icons/go";
import { IoMenuSharp } from "react-icons/io5";
import { Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';
import logo from '../../Assets/Image/logo.png'
import paths from '../../Config/paths';
import { MenuOutlined } from '@ant-design/icons';

const LandingPage = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const SignUp = () => {
    history.push(paths?.SIGNUP)
  }

  const toggleDrawer = (open) => () => {
    setDrawerVisible(open);
  }

  return (
    <div className='mainDivLandingPage'>
      <div className="innerLandingPageDiv">
        <div className='landingHeaderDiv'>
          {isMobile ? (
            <>
              <div className="innerLandingHeader">

                <div className="mobileHeader">

                  <div className="logoVideoAi mobileheaderLogo">
                    <img className='logo' src={logo} alt="" />
                  </div>
                  <div>
                    <MenuDropDown />
                  </div>

                </div>
                <div className='LandingpageMainHeading'>
                  <h1>Create Stunning AI Real Estate Videos in Minutes!</h1>
                  <p>Transform your ideas into professional Real Estate Videos effortlessly with just a prompt or by using our customizable templates.</p>
                  {/* <button className='GeneratedReal Estate Videobutton' onClick={() => history.push(paths?.LOGIN)}> <span>Generate Real Estate Video</span></button> */}
                </div>
              </div>

            </>
          ) : (
            <div className="innerLandingHeader">
              <div className="navbarSection">
                <div className="logoReal Estate VideoAi">
                  <img className='logo' src={logo} alt="" />
                </div>
                <div>
                  <MenuDropDown />
                </div>
              </div>
              <div className='LandingpageMainHeading'>
                <h1>Create Stunning AI Real Estate Videos in Minutes!</h1>
                <p>Transform your ideas into professional Real Estate Videos effortlessly with just a prompt or by using our customizable templates.</p>
                {/* <button onClick={() => history.push(paths?.LOGIN)} className='GeneratedReal Estate Videobutton'>
                  <span>Generate Real Estate Video</span>
                </button> */}
              </div>
            </div>
          )}


        </div>
        <div className="ImageSection">
          <div className="InnerImageSection">
            <img src={VideoImg} alt="Real Estate Video Image" className='img' />
          </div>
        </div>
        <div className="HowItsWorkSection" style={{ marginTop: '150px' }}>
          <div className="HowItsWorkInnerSection" id="how-it-works-section">
            <h1>
              How It Works
            </h1>
            <p>Creating AI-powered Real Estate Videos has never been easier. With our intuitive platform, you can go from concept to production in just a few simple steps:</p>

            <ul className="how-it-works-list">
              <li>For <b>listing agents</b> , it’s the perfect way to showcase properties with professional videos that reinforce your brand and attract buyers.</li>
              <li>For <b>buyer’s agents</b> , create personalized, branded tours for clients, even for properties you don’t list, to enhance your service and stand out.</li>
              <li>For <b>independent developers</b> and renovators, it’s an affordable solution to market single properties or small projects with high-quality visuals that highlight key features.</li>
            </ul>

            <p className='how-it-works-text'>Unlike traditional videographers, who charge $200 to $500 per video, RealVisionAI lets you create multiple videos each month starting at a fraction of the cost —saving you time and money while delivering stunning results.
            </p>


          </div>

          <div className="HowItsWorkCards">
            <div className="howItsWorkInnerCardSection">
              <div className="cardsDiv">
                <div className="cardsInnerDiv">
                  <div className="CardImageSection">
                    {/* <div className="imageDiv">
                      <p>a Real Estate Video for real estate....</p>
                    </div> */}
                    {/* <img src={AIicon} alt="Real Estate Video Image" className='aiICon' /> */}

                    <div className="CardImageSection imgsection">
                      <img src={AIicon} alt="" className='multimedia aiICon' />
                    </div>

                  </div>
                  <div className="cardsparagrap">
                    <h3>Plan Your Real Estate Video</h3>
                    <p>Describe the Real Estate Video you want to create in the text field. Be as specific as possible and choose a style tailored to your needs. Options include social media-friendly, business-focused, training-oriented, or property showcase videos. Highlight key details like the property features or your target audience for best results.</p>
                  </div>
                </div>
              </div>
              <div className="cardsDiv">
                <div className="cardsInnerDiv">
                  <div className="CardImageSection imgsection">
                    <img src={cut} alt="" className='cutIcon' />
                    <img src={multimedia} alt="" className='multimedia' />
                  </div>
                  <div className="cardsparagrap">
                    <h3>Create and Customize Your Video</h3>
                    <p>Generate your Real Estate Video and personalize it using our professional editing tools. Easily upload and integrate video clips, property photos, or your own footage. Add voiceovers using AI-powered text-to-speech or import your recordings to create a polished, engaging video in no time.</p>
                  </div>
                </div>
              </div>
              <div className="cardsDiv">
                <div className="cardsInnerDiv">
                  <div className="CardImageSection">
                    <div className="CardImageSection imgsection">
                      {/* <span className='RotateDiv'>
                        <img src={RotateIcon} alt="" className='rotate' />

                      </span> */}
                      <img src={cut} alt="" className='cutIcon' />

                      <img src={arrow} alt="" className='arrow' />
                    </div>
                  </div>
                  <div className="cardsparagrap">
                    <h3>Download and Share Your Video</h3>
                    <p>Download your Real Estate Video in high-quality format, ready for any platform. Whether it's a listing site, social media, or email campaigns, your videos will stand out with exceptional clarity and sharpness. Help your clients buy or sell properties by showcasing their unique features with confidence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ButtongeneratedVideo">
              <button onClick={() => history.push(paths?.LOGIN)}>Generate Real Estate Video</button>
            </div>
          </div>
        </div>
        <div className="HowItsWorkSection">
          <div className="HowItsWorkInnerSection" id='features-section'>
            <h1>Why use RealVisionAI?</h1>
            <p>Unlock the power of AI to create high-quality Real Estate Videos effortlessly with just a few clicks. Our tool offers customizable templates, an intuitive interface, and powerful AI-driven features that make Real Estate Video production accessible to everyone.</p>
          </div>
          <div className="WhyChooseUsParagraphDiv">
            {
              WhyChooseUs.map((v, i) => {
                const getColorClass = (countHeading) => {
                  switch (countHeading?.toLowerCase()) {
                    case 'one':
                      return 'color-one';
                    case 'two':
                      return 'color-two';
                    case 'three':
                      return 'color-three';
                    case 'four':
                      return 'color-four';
                    default:
                      return '';
                  }
                };
                return (
                  <div className="WhyChoosUsparagraphInner" key={i}>
                    <div className={`HeadingCounting ${getColorClass(v?.countHeading)}`}>
                      <span>{v?.countHeading}</span>
                    </div>
                    <div className="SideHeadingOfwhyChoosUs">
                      <h2>{v?.sideHeading}</h2>
                    </div>
                    <div className="WhyChoosUsParagra">
                      <p>{v?.paragraph}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className="ButtongeneratedVideo">
            <button onClick={() => history.push(paths?.LOGIN)}>Generate Real Estate Video</button>
          </div>
        </div>
        {/* <div className="Tools">
          <div className="ToolsInnerDiv">
            <h1>Learn more about our AI Real Estate Video generator and editing tools</h1>
            <p>Learn more about our AI Real Estate Video generator and editing tools</p>

          </div>
          <div className="toolImg">
            <div className="toolImgInnerImageSection">
              <img src={Tool} alt="" className='img' />
            </div>
          </div>
        </div> */}
        <div className="middleHeader">
          <div className="MiddleInnerHeader">
            <div>
              <p className='middleParagraph'>No technical skills required</p>
            </div>
            <div className="arrowboth">
              <GoArrowBoth size={20} />

            </div>
            <div>
              <p className='middleParagraph'>AI-powered Real Estate Video generation</p>
            </div>
            <div className="arrowboth">
              <GoArrowBoth size={20} />

            </div>
            <div>
              <p className='middleParagraph'>Ready-to-use templates</p>
            </div>

          </div>
        </div>
        {/* <Reviews /> */}
        <div id='faqs-section'>
          <Faqs />
        </div>
        <div className="ReadyTomake">
          <div className="ReadyToMakeInner">
            <div className="ReadySection">
              <h1>Ready to Make Your First AI Real Estate Video?</h1>
              <p>Start making professional Real Estate Videos effortlessly, whether you’re using a prompt or one of our customizable templates. Dive in and see just how simple Real Estate Video creation can be!</p>
              <div className='GeneratedReadyButton'>
                <button onClick={() => history.push(paths?.LOGIN)}>Generated Real Estate Video</button>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </div>
  )
}

export default LandingPage
