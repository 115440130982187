import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Form, Input, message, Spin } from 'antd'
import { jwtDecode } from 'jwt-decode'
import { signup } from '../../Assets'
import { AUTH } from '../../utils/apis'
import { FcGoogle } from "react-icons/fc"
import { auth } from '../../Config/firebase'
import { errorMessage } from '../../utils/helpers'
import { setUser } from '../../Redux/actions/action'
import { googleClientId } from '../../utils/constants'
import { getAuthErrorMessage } from '../../utils/helpers'
import { setLoginData } from '../../Redux/actions/authActions'
import { doc, setDoc } from 'firebase/firestore';
import paths from '../../Config/paths';
const Login = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [userData, setUserData] = useState(null)
    const [googleData, setGoogleData] = useState(null)

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: googleClientId,
                callback: updateGoogleLogin
            })

            window.google.accounts.id.renderButton(
                document.getElementById('signInDiv'),
                {
                    theme: 'filled_blue',
                    size: 'large',
                    longtitle: true,
                    scope: 'profile email',
                    width: '100%',
                    state_cookie_domain: 'localhost'

                }
            )

            window.google.accounts.id.prompt()
        }
    }, [window.google])


    const handleLogin = async () => {
        if (!email || !password) {
            message.error('Please fill in all fields.')
            return
        }
        setLoading(true)
        try {
            const response = await fetch(AUTH?.LOGIN, {
                body: JSON.stringify({ email, password }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            const data = await response?.json()
            if (data?.user) {
                // setToken(idToken)
                setError('')
                dispatch(setLoginData(data?.user))
                message.success('Login successfully!')
                history.push(paths?.HOME)
            } else {
                message.error(data?.message || 'Login failed. Please try again.');
            }
        } catch (error) {
            console.log(error)

            if (error && error.message) {
                message.error(getAuthErrorMessage(error))
            } else {
                message.error('An unknown error occurred. Please try again later.')
            }
        } finally {
            setLoading(false)
        }
    }

    const updateGoogleLogin = async (e) => {
        const data = jwtDecode(e?.credential)


        try {
            const newUserGoogleData = {
                uid: data.sub,
                email: data.email,
                displayName: data.name,
                photoURL: data.picture,
            }
            const response = await axios.post(AUTH?.GOOGLELOGIN, newUserGoogleData);

            setGoogleData(newUserGoogleData)
            dispatch(setLoginData(newUserGoogleData))
            console.log(newUserGoogleData, 'user google data')
            if (newUserGoogleData) {
                message.success('Login successfully!')
                history.push('/home')
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const showPasswordFun = () => {
        setShowPassword(!showPassword)
    }
    const GotSignUp = () => {
        history.push(paths?.SIGNUP)
    }
    const forgot = () => {
        history.push(paths?.FORGOT_PASSWORD)
    }


    return (
        <div className='card-parent-wrapper'>
            <div className='cardFlexDiv'>
                <div className="sideImgsignup">
                    <img src={signup} alt="" />
                </div>
                <div className='card-parent'>
                    <div className='card'>
                        <div className="signupHeading">
                            <h1>Login</h1>
                        </div>
                        <div className="signupInpu">
                            <div className="mb-6 marginInput">
                                <label htmlFor="email" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-6 marginInput relative">
                                <label htmlFor="password" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Password
                                </label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    placeholder="Password"
                                    className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 right-0 mt-7 pr-4 flex items-center cursor-pointer"
                                    onClick={showPasswordFun}
                                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                                >
                                    {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                </div>
                            </div>
                            <div className='SignupButton' onClick={handleLogin}>
                                <button type="primary" disabled={loading}>
                                    {loading ? <Spin className="custom-spin" size="small" style={{ marginRight: 8, color: 'white' }} /> : 'Login'}
                                </button>
                            </div>
                            <p>Don't Have an account? <span className='gotoLogin' onClick={GotSignUp}>Sign Up</span></p>
                            <p onClick={forgot} className='gotoLogin'>Forgot password</p>

                        </div>
                    </div>
                    <div id='signInDiv' className='google-btn'></div>

                </div>
            </div>
        </div>
    )
}

export default Login
