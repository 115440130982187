// src/reducers/authReducer.js
import { SET_LOGIN_DATA ,REMOVE_USER} from '../actions/authActions';

const initialState = {
    loginData: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGIN_DATA:
            return {
                ...state,
                loginData: action.payload,
            };
        case REMOVE_USER: {
            return { ...state, loginData: null }
        }
        
        default:
            return state;
    }
};

export default authReducer;
