
import React, { useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import allPaths from './paths';
import { Login, Home, Projects, PrivacyPolicy, GeneratingVideo, ForgotPassword, LandingPage, Signup, Settings, CopyrightPolicy, TermsConditions, ContactUs } from '../Screens';
import { MenuLayout } from '../Components';
import { VideoPreview } from '../Components';
import Templates from '../Screens/Templates/Templates';
import paths from './paths';
const Page404 = (props) => {
    const { history } = props;
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button
                    type="primary"
                    className="form-button"
                    onClick={() => history.push(paths?.HOME)}
                >
                    Back Home
                </Button>
            }
        />
    );
};

const Routes = () => {
    return (
        <Router>
            <Switch>
                <MenuLayout
                    path={allPaths?.HOME}
                    exact
                    component={Home}
                />
                <MenuLayout
                    path={allPaths?.PROJECT}
                    exact
                    component={Projects}
                />
                <MenuLayout
                    path={allPaths?.TEMPLATES}
                    exact
                    component={Templates}
                />
                <MenuLayout
                    path={allPaths?.SETTINGS}
                    exact
                    component={Settings}
                />
                <Route
                    path={allPaths?.CREATE_VIDEO}
                    exact
                    component={GeneratingVideo}
                />
                <Route
                    path={allPaths?.LANDING_PAGE}
                    exact
                    component={LandingPage}
                />
                <Route
                    path={allPaths?.SIGNUP}
                    exact
                    component={Signup}
                />
                <Route
                    path={allPaths?.LOGIN}
                    exact
                    component={Login}
                />
                <Route
                    path={allPaths?.PREVIEW}
                    exact
                    component={VideoPreview}
                />
                <Route
                    path={allPaths?.FORGOT_PASSWORD}
                    exact
                    component={ForgotPassword}
                />
                <Route
                    path={allPaths?.PRIVACY_POLICY}
                    exact
                    component={PrivacyPolicy}
                />
                <Route
                    path={allPaths?.COPYRIGHT_POLICY}
                    exact
                    component={CopyrightPolicy}
                />
                <Route
                    path={allPaths?.TERMS_CONDITIONS}
                    exact
                    component={TermsConditions}
                />
                <Route
                    path={allPaths?.CONTACT_US}
                    exact
                    component={ContactUs}
                />

                <Route path="/:page404" exact component={Page404} />
            </Switch>
        </Router>
    );
};

export { Page404, Routes };