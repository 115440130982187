import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { POST } from '../../utils/apis';
import axios from 'axios';
import { message, Spin } from 'antd';

const ProfileSetting = () => {
    const userData = useSelector((state) => state.auth.loginData);
    const uid = userData?.uid;
    const [newName, setNewName] = useState(userData.displayName || '');
    const [loading, setLoading] = useState(false); 
    const handleOk = async () => {
        if (!newName) {
            message.error('Please enter a name.');
            return;
        }

        setLoading(true); 

        try {
            const response = await axios.post(POST?.CHANGENAME, {
                uid,
                displayName: newName,
            });

            console.log(response.data);
            message.success('Name updated successfully!');
        } catch (error) {
            console.error('Error:', error);
            message.error('Error updating user name.'); 
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='recentVideos'>
                <div className="SetingInner">
                    <div>
                        <div className='SettingInp'>
                            <div className="mb-6 settingInput">
                                <label htmlFor="email-input" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input
                                    type="text"
                                    id="email-input"
                                    placeholder="Email"
                                    value={userData.email}
                                    className="block disblaeInput w-full h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                    disabled
                                />
                            </div>
                            <div className="mb-6 settingInput">
                                <label htmlFor="name-input" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                                <input
                                    type="text"
                                    id="name-input"
                                    placeholder="Full Name"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    className="block w-full h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="UpdateProfileButton" onClick={handleOk} style={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
                    {loading ? <Spin size="small" /> : 'Update'}
                </div>
            </div>
        </>
    );
};

export default ProfileSetting;
