import React from 'react'
import { useHistory } from 'react-router-dom';
import paths from '../../Config/paths';
import { logo } from '../../Assets'
const Footer = () => {
  const history = useHistory()
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <div className='MainFooterDiv'>
      <div className="footerlogo-div">
        <img src={logo} alt="" className='footer-logo' />

      </div>
      <div className="footerList">
        <ul>
          <li
            onClick={() => {
              history.push(paths?.LANDING_PAGE)
              setTimeout(() => scrollToSection('how-it-works-section'), 100)
            }}
          >
            How It Works
          </li>

          <li
            onClick={() => {
              history.push(paths?.LANDING_PAGE)
              setTimeout(() => scrollToSection('features-section'), 100)
            }}
          >
            Features
          </li>
          <li
            onClick={() => {
              history.push(paths?.LANDING_PAGE)
              setTimeout(() => scrollToSection('faqs-section'), 100)
            }}
          >
            FAQs
          </li>          <li onClick={() => history.push(paths?.CONTACT_US)} >Contact us</li>
          <li onClick={() => history?.push(paths?.PRIVACY_POLICY)}>Privacy Policy</li>
          <li onClick={() => history?.push(paths?.COPYRIGHT_POLICY)}>Copyright Policy</li>
          <li onClick={() => history?.push(paths?.TERMS_CONDITIONS)}>Terms & Conditions</li>
        </ul>
      </div>
      <div className="copyRight">
        <p>2024 - All rights are reserved</p>
      </div>
    </div>
  )
}

export default Footer
