import React from 'react'

const Progress = ({progress}) => {
    return (
        <div className="createVideomainDiv progressInnerDiv">
            <div className="CreateVideoInnerDiv ">
                <div className="loader">
                </div>
                <div className="generatingVideo">
                    <span>Generating your video... ({progress}%)</span>
                </div>
                <span className='progressParagraph'>We’re Creating Something Amazing for You—Hang Tight, It’s Almost Done.</span>
            </div>
        </div >
    )
}

export default Progress
