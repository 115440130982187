import React, { useState } from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import { drawerRoutes } from '../../utils/constants';
import { IoIosLogOut } from "react-icons/io";
import paths from '../../Config/paths';
import { useDispatch } from 'react-redux';
import { removeUser } from '../../Redux/actions/authActions';
function SideMenu() {
    const history = useHistory();
    const location = window.location.pathname;
    const dispatch = useDispatch();

    const getActiveMenuIndex = (pathname) => {
        return drawerRoutes.findIndex(route => route.route === pathname);
    };
    
    const [activeMenuIndex, setActiveMenuIndex] = useState(getActiveMenuIndex(location));

    const handleMenuClick = (route, index) => {
        setActiveMenuIndex(index); 
        history.push(route); 
    };

    return (
        <Menu
            defaultOpenKeys={[`${activeMenuIndex}`]}
            defaultSelectedKeys={[`${activeMenuIndex}`]}
            style={{ height: '100vh', maxWidth: '80%', borderRadius: '10px' }}
            className='MenuSideBar'
            mode='inline'
        >
            <div className='CreateVideoButton'  onClick={()=>history.push(paths?.CREATE_VIDEO)}>
                <div><IoMdAdd size={20} /></div>
                <div className='CreateVideoName'>Create Video</div>
            </div>
            {drawerRoutes.map((v, i) => (
                <Menu.Item
                    key={i}
                    icon={React.cloneElement(v.icon, {
                        style: { color: activeMenuIndex === i ? 'white' : 'black' } // Conditional color
                    })}
                    className="custom-menu-item"
                    style={{ marginBottom: '20px' }}
                    onClick={() => handleMenuClick(v.route, i)}
                >
                    <span className='SidemenuTitle' style={{ color: activeMenuIndex === i ? 'black' : '#858585' }}>
                        {v.title}
                    </span>
                </Menu.Item>
            ))}
            <div className="logout" onClick={() => {
                dispatch(removeUser());
                history.push(paths?.LOGIN);
            }}>
                <div className="logouticon">
                    <span><IoIosLogOut /></span>  
                </div>
                <div>
                    Log Out 
                </div>
            </div>
        </Menu>
    );
}

export default SideMenu;
