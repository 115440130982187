// src/actions/authActions.js

export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const REMOVE_USER = 'REMOVE_USER';


export const setLoginData = (userData) => {
    return {
        type: SET_LOGIN_DATA,
        payload: userData,
    };
};

const removeUser = () => {
    return {
        type: REMOVE_USER
    }
}


export {
    removeUser
}