import React, { useState, useEffect } from 'react';
import { message, Upload, Button, Spin, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { POST } from '../../utils/apis';

const Uploader = ({ handleDataUpdate, setUploader, setGenerateVideo, initialData }) => {
    const [loading, setLoading] = useState(false);
    const [imageFiles, setImageFiles] = useState(initialData?.imageFiles || []);
    const [videoFiles, setVideoFiles] = useState(initialData?.videoFiles || []);
    const [audioFiles, setAudioFiles] = useState(initialData?.audioFiles || []);
    const [pdfFiles, setPdfFiles] = useState(initialData?.pdfFiles || []);
    const [imageStartTime, setImageStartTime] = useState(initialData?.imageStartTime || 0);
    const [imageEndTime, setImageEndTime] = useState(initialData?.imageEndTime || 10);
    const [videoStartTime, setVideoStartTime] = useState(initialData?.videoStartTime || 0);
    const [videoEndTime, setVideoEndTime] = useState(initialData?.videoEndTime || 10);
    const [fileType, setFileType] = useState('');

    const uploadFiles = async (formData) => {
        try {
            const response = await axios.post(POST?.UPLOAD, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            const data = response?.data;
            const payloads = data.results.map(result => ({
                authUrl: result.authUrl,
                fileType: result.fileType,
            }));
            return payloads;
        } catch (error) {
            console.error("Error uploading file:", error);
            message.error("Error uploading file. Please try again.");
            return null;
        }
    }
    
    const uploadPdf = async (files) => {
        if (!files || files.length === 0) {
            message.error("Please select a PDF file.");
            return null;
        }
    
        const formData = new FormData();
        files.forEach(file => {
            formData.append("pdfFiles", file.originFileObj || file); // Use originFileObj
        });
    
        try {
            const response = await axios.post(POST?.PDFUPLOAD, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
    
            console.log('PDF parsed successfully:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error uploading PDF:', error.response ? error.response.data : error.message);
            return null;
        }
    };
    
    
    const handleImageUpload = (fileList) => {
        if (imageStartTime === undefined || imageStartTime === '') {
            message.error("Please enter a start time before uploading images.");
            return;
        }
        
        const newFiles = fileList.map(file => ({
            uid: file.uid || `-${Date.now()}`,
            name: file.name,
            status: 'done',
            originFileObj: file
        }));
        setImageFiles([...imageFiles, ...newFiles]);
        setFileType('image');
    }

    const handleVideoUpload = (fileList) => {
        if (videoStartTime === undefined || videoStartTime === '') {
            message.error("Please enter a start time before uploading videos.");
            return;
        }
        if (!fileList[0].type.includes('mp4')) {
            message.error('Please upload MP4 video files only');
            return;
        }
        const newFiles = fileList.map(file => ({
            uid: file.uid || `-${Date.now()}`,
            name: file.name,
            status: 'done',
            originFileObj: file,
            startTime: videoStartTime,
            endTime: videoEndTime
        }));
        setVideoFiles([...videoFiles, ...newFiles]);
        setFileType('video');
    }

    const handleAudioUpload = (fileList) => {
        const newFiles = fileList.map(file => ({
            uid: file.uid || `-${Date.now()}`,
            name: file.name,
            status: 'done',
            originFileObj: file
        }));
        setAudioFiles([...audioFiles, ...newFiles]);
        setFileType('audio');
    }

   const handlePdfUpload = (fileList) => {
    const newFiles = fileList.map(file => ({
        uid: file.uid || `-${Date.now()}`,
        name: file.name,
        status: 'done',
        originFileObj: file.originFileObj || file // Use originFileObj if available
    }));
    setPdfFiles([...pdfFiles, ...newFiles]);
};


    const handleRemoveImage = (fileToRemove) => {
        const updatedFiles = imageFiles.filter(file => file.uid !== fileToRemove.uid);
        setImageFiles(updatedFiles);
    };

    const handleRemoveVideo = (fileToRemove) => {
        const updatedFiles = videoFiles.filter(file => file.uid !== fileToRemove.uid);
        setVideoFiles(updatedFiles);
    };

    const handleRemoveAudio = (fileToRemove) => {
        const updatedFiles = audioFiles.filter(file => file.uid !== fileToRemove.uid);
        setAudioFiles(updatedFiles);
    };

    const handleRemovePdf = (fileToRemove) => {
        const updatedFiles = pdfFiles.filter(file => file.uid !== fileToRemove.uid);
        setPdfFiles(updatedFiles);
    };

    const handleNext = async () => {
        if (videoFiles.length > 0 && (!videoStartTime || videoStartTime === '')) {
            message.error("Please enter a start time for the video before proceeding.");
            return;
        }

        if (imageFiles.length > 0 && (!imageStartTime || imageStartTime === '')) {
            message.error("Please enter a start time for the images before proceeding.");
            return;
        }

        setLoading(true);
    
        let payloads = [];
        let pdfData = []; 
    
        if (imageFiles.length || videoFiles.length || audioFiles.length) {
            const formData = new FormData();
            
            const processFiles = (files, fileType) => {
                files.forEach(file => {
                    if (file.payload) {
                        payloads.push(file.payload);
                    } else {
                        formData.append('files[]', file.originFileObj || file);
                        if (fileType === 'video') {
                            formData.append('videoStartTime', file.startTime);
                            formData.append('videoEndTime', file.endTime);
                        }
                    }
                });
            };

            processFiles(imageFiles, 'image');
            processFiles(videoFiles, 'video');
            processFiles(audioFiles, 'audio');

            formData.append('imageStartTime', imageStartTime);
            formData.append('imageEndTime', imageEndTime);

            if (formData.has('files[]')) {
                const uploadedPayloads = await uploadFiles(formData) || [];
                payloads = [...payloads, ...uploadedPayloads];
            }
        }
    
        if (pdfFiles.length > 0) {
            const uploadedPdfData = await uploadPdf(pdfFiles); 
            if (uploadedPdfData) {
                pdfData = uploadedPdfData; 
            }
        }
    
        handleDataUpdate({
            pdfData,
            payloads,
            imageStartTime,
            imageEndTime,
            videoStartTime,
            videoEndTime,
            imageFiles,
            videoFiles,
            audioFiles,
            pdfFiles
        });
    
        setLoading(false);
        setUploader(true)
        setGenerateVideo(false)
    }

    return (
        <div className="uploader-container">
            <div className="uploader-inner">
                <div className="uploader-step">
                    <span>step 06 / 07</span>
                </div>
                <h2 className='uploader-heading'>Select the perfect video dimensions to match your platform and audience.</h2>
                <div className="uploader-grid">
                    <div className='uploader-card'>
                        <div className="">
                            <p className="uploader-card-title">Upload Image</p>
                            <Upload
                                accept="image/*"
                                beforeUpload={file => {
                                    handleImageUpload([file]);
                                    return false;
                                }}
                                fileList={imageFiles}
                                onRemove={handleRemoveImage}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            <p style={{ marginTop: '10px' }}>Upload Image to insert</p>
                            <div className='uploader-input-group'>
                                <p style={{ color: 'black' }}>Enter Start Time (in seconds):</p>
                                <Input
                                    type="number"
                                    value={imageStartTime}
                                    onChange={(e) => setImageStartTime(e.target.value)}
                                    min={0}
                                />
                                <p style={{ color: 'black' }}>Enter End Time (in seconds):</p>
                                <Input
                                    type="number"
                                    value={imageEndTime}
                                    onChange={(e) => setImageEndTime(e.target.value)}
                                    min={0}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='uploader-card'>
                        <div className="">
                            <p className="uploader-card-title">Upload Video</p>
                            <Upload
                                accept="video/*"
                                beforeUpload={file => {
                                    handleVideoUpload([file]);
                                    return false;
                                }}
                                fileList={videoFiles}
                                onRemove={handleRemoveVideo}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            <p style={{ marginTop: '10px' }}>Upload Video to insert</p>
                            <div className="uploader-input-group">
                                <p style={{ color: 'black' }}>Enter Start Time (in seconds):</p>
                                <Input
                                    type="number"
                                    value={videoStartTime}
                                    onChange={(e) => setVideoStartTime(e.target.value)}
                                    min={0}
                                />
                                <p style={{ color: 'black' }}>Enter End Time (in seconds):</p>
                                <Input
                                    type="number"
                                    value={videoEndTime}
                                    onChange={(e) => setVideoEndTime(e.target.value)}
                                    min={0}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='uploader-card-large'>
                        <div className="uploader-card-content">
                            <p className="uploader-card-title">Upload PDF</p>
                            <Upload
                                accept="application/pdf"
                                beforeUpload={file => {
                                    handlePdfUpload([file]);
                                    return false;
                                }}
                                multiple={true}
                                fileList={pdfFiles}
                                onRemove={handleRemovePdf}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            <p style={{ marginTop: '40px' }}>Upload a PDF file to enhance your video with valuable information.Add documents,or extra details to engage your viewers further</p>
                        </div>
                    </div>

                    <div className='uploader-card-large'>
                        <div className="uploader-card-content">
                            <p className="uploader-card-title">Upload Audio</p>
                            <Upload
                                accept=".mp3,.wav,.ogg,.aac,.m4a"
                                beforeUpload={file => {
                                    handleAudioUpload([file]);
                                    return false;
                                }}
                                className='uploader-audio'
                                fileList={audioFiles}
                                onRemove={handleRemoveAudio}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            <p style={{ marginTop: '40px' }}>Upload an audio file to add as background sound in your video. This will enhance the ambiance and provide a richer viewing experience</p>
                        </div>
                    </div>
                </div>

                <div className="uploader-button" onClick={handleNext}>
                    <button  disabled={loading}>
                        {loading ? <Spin className="custom-spin" /> : 'Next'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Uploader;
