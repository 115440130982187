import React, { useState, useEffect } from 'react';
import { GenerateVideoOptions } from '../../utils/constants';
import axios from 'axios';
import { AUTH, POST } from '../../utils/apis';
import { useSelector } from 'react-redux';
import { message } from 'antd';

const GenerateVideo = ({ resetProgress, socket, formData, setGenerateVideo, setCreateVideo, handleDataUpdate, setVideoPreviewUrl, setShowProgress }) => {
    const [selectedDiv, setSelectedDiv] = useState(null);
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.auth.loginData);

    const handleClick = (index) => {
        setSelectedDiv(index);
    };

    const Next = async () => {
        const uersUid = userData?.uid;
        const userEmail = userData?.email;

        if (selectedDiv !== null) {
            const selectedData = {
                heading: GenerateVideoOptions[selectedDiv]?.heading,
                size: GenerateVideoOptions[selectedDiv]?.size,
            };

            const combinedData = {
                ...formData,
                generateVideo: selectedData,
                uid: uersUid,
                userEmail: userEmail,
                socketId: socket.id,
            };
            resetProgress()
            setLoading(true);
            try {
                const response = await axios.post(POST?.CREATEVIDEO, combinedData);
                console.log('Server response:', response?.data);
                
                const videoUrl = response?.data?.videoUrl;
                const address = response?.data?.essentials?.address || response?.data?.address;
                
                if (videoUrl) {
                    setShowProgress(true);
                    setVideoPreviewUrl({
                        url: videoUrl,
                        address: address
                    });
                    console.log('Setting video preview with:', { url: videoUrl, address: address });
                }

                handleDataUpdate(combinedData);
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    if (error.response.data.error && error.response.data.error.fields) {
                        error.response.data.error.fields.forEach(field => {
                            message.error(`${field.errors}`);
                        });
                    } else {
                        message.error('An unexpected error occurred, please try again.');
                    }
                    setGenerateVideo(false);
                    setCreateVideo(true);
                } else {
                    console.error('Unexpected error:', error);
                    message.error('An unexpected error occurred.');
                    setGenerateVideo(false);
                    setCreateVideo(true);
                }
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) {
        setGenerateVideo(true);
    }

    return (
        <div className="createVideomainDiv">
            <div className="CreateVideoInnerDiv">
                <div className="stepsCount">
                    <span>step 07 / 07</span>
                </div>
                <h2 className='infoHeading'>Select the perfect video dimensions to match your platform and audience.</h2>
                <div className="createVideoSectionIneerDiv">
                    {GenerateVideoOptions.map((v, index) => (
                        <div
                            key={index}
                            className={`AddVideo ${selectedDiv === index ? 'active' : ''}`}
                            onClick={() => handleClick(index)}
                            style={{ border: selectedDiv === index ? '2px solid #0293F2' : 'none' }}
                        >
                            <div className="AddVideoInner">
                                <div
                                    className="AddVideoSize"
                                    style={{
                                        border: selectedDiv === index ? '2px solid #0293F2' : '2px solid grey',
                                        color: selectedDiv === index ? '#0293F2' : 'black',
                                    }}
                                >
                                    {v?.size}
                                </div>
                                <p className="Addvideoheading" style={{ color: 'black' }}>
                                    {v.heading}
                                </p>
                                <p>{v.paragraph}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="nextButton" onClick={Next}>
                    <button>Generate Video</button>
                </div>
            </div>
        </div>
    );
};

export default GenerateVideo;
