import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message, Spin } from 'antd';
import { AUTH } from '../../utils/apis';
import axios from 'axios';
import paths from '../../Config/paths';
import { signup } from '../../Assets';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { setUser } from '../../Redux/actions/action';
import { setLoginData } from '../../Redux/actions/authActions'
import { googleClientId } from '../../utils/constants'
import { jwtDecode } from 'jwt-decode'
import logo from '../../Assets/Image/logo.png'
const Signup = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [displayName, SetDisplayName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [googleData, setGoogleData] = useState(null)
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: googleClientId,
                callback: updateGoogleLogin
            })

            window.google.accounts.id.renderButton(
                document.getElementById('signInDiv'),
                {
                    theme: 'filled_blue',
                    size: 'large',
                    longtitle: true,
                    scope: 'profile email',
                    width: '100%',
                    state_cookie_domain: 'localhost',
                }
            )

            window.google.accounts.id.prompt()
        }
    }, [window.google])
    const updateGoogleLogin = async (e) => {
        const data = jwtDecode(e?.credential)

        // console.log('data', data)

        try {
            const newUserGoogleData = {
                uid: data.sub,
                email: data.email,
                displayName: data.name,
                photoURL: data.picture,
            }
            const response = await axios.post(AUTH?.GOOGLELOGIN, newUserGoogleData);

            console.log(response.data, 'API response')
            setGoogleData(newUserGoogleData)
            dispatch(setLoginData(newUserGoogleData))
            console.log(newUserGoogleData, 'user google data')
            if (newUserGoogleData) {
                message.success('Login successfully!')
                history.push(paths?.HOME)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const showPasswordFun = () => {
        setShowPassword(!showPassword);
    };
    const handleChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const submit = async (e) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

        if (!passwordRegex.test(password)) {
            message.error('Password must be at least 8 characters long and contain at least one letter and one symbol.');
            return;
        }
        if (!displayName || !username) {
            message.error('Please fill in all fields');
            return;
        }

        setLoading(true);
        const formData = {
            email,
            password,
            displayName,
            username
        };

        try {
            const response = await axios.post(AUTH?.SIGNUP, formData);
            console.log(response, 'signup data');
            const userData = response.data;
            console.log(userData);

            message.success('Please check your email to verify your account before logging in');
            history.push(paths?.LOGIN);
        } catch (error) {
            if (error.response) {
                switch (error?.response.status) {
                    case 409:
                        message.error(error?.response.data.error);
                        break;
                    case 400:
                        message.error(error?.response.data.error);
                        break;
                    case 500:
                        message.error(error.response.data.error);
                        break;
                    default:
                        message.error(error.response.data.error);
                        break;
                }
            } else {
                message.error('An error occurred while connecting to the server.');
            }
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const GotLoginUp = () => {
        history.push(paths?.LOGIN);
    };

    return (
        <>
            <div className="SignUpHeader">
                <div className="signupInnerHeader">
                    <div className="logoVideoAi mobileheaderLogo" onClick={() => history.push(paths?.LANDING_PAGE)}>
                        <img className='logo' src={logo} alt="" />
                    </div>
                </div>

            </div>
            <div className='card-parent-wrapper'>
                <div className='cardFlexDiv margin'>
                    <div className="sideImgsignup">
                        <img src={signup} alt="" />
                    </div>
                    <div className='card-parent'>
                        <div className='card'>
                            <div className="signupHeading">
                                <h1>Signup</h1>
                            </div>
                            <div className="signupInput">
                                <div className="mb-6 marginInput">
                                    <label htmlFor="full-name" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                                    <input
                                        type="text"
                                        id="full-name"
                                        name="name"
                                        placeholder="Enter Full Name"
                                        className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                        value={displayName}
                                        onChange={handleChange(SetDisplayName)}
                                    />
                                </div>
                                <div className="mb-6 marginInput">
                                    <label htmlFor="username" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter Username"
                                        className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                        value={username}
                                        onChange={handleChange(setUsername)}
                                    />
                                </div>
                                <div className="mb-6 marginInput">
                                    <label htmlFor="email" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                        value={email}
                                        onChange={handleChange(setEmail)}
                                    />
                                </div>
                                <div className="mb-6 marginInput relative">
                                    <label htmlFor="password" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Password
                                    </label>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 pr-4 mt-7 flex items-center cursor-pointer"
                                        onClick={showPasswordFun}
                                    >
                                        {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                    </div>
                                </div>
                                <div className='SignupButton' onClick={submit}>
                                    <button type="primary" disabled={loading}>
                                        {loading ? <Spin size="small" className="custom-spin" style={{ marginRight: 8 }} /> : 'Signup'}
                                    </button>
                                </div>
                                <p>Already Have an account? <span onClick={GotLoginUp} className='gotoLogin'>Login</span></p>
                            </div>
                            <div id='signInDiv' className='google-btn'></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Signup;
