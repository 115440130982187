import React, { useState,useEffect } from 'react';
import { Checkbox, message ,Spin} from 'antd';

const Highlight = ({ setNeighborhood, setHighlight, handleDataUpdate,initialData }) => {
    const [yearBuilt, setYearBuilt] = useState('');
    const [lotSize, setLotSize] = useState('');
    const [loading, setLoading] = useState(false); 
    const [bedrooms, setBedrooms] = useState('');
    const [amenities, setAmenities] = useState({
        fireplace: false,
        highCeilings: false,
        hardwoodFloors: false,
        largeWindows: false,
        walkInCloset: false,
        homeOffice: false,
        stainlessSteelAppliances: false,
        kitchenIsland: false,
        graniteCountertops: false,
        marbleCountertops: false,
        pool: false,
        patio: false,
        outdoorKitchen: false,
        privateYard: false,
        balcony: false,
        fitnessCenter: false,
        clubhouse: false,
        communityPool: false,
        security: false,
        attachedGarage: false,
        evChargingStation: false,
        smartHomeSystem: false,
        homeSecuritySystem: false,
        solarPanels: false,
    });
    
    useEffect(() => {
        if (initialData) {
            setYearBuilt(initialData.yearBuilt || '');
            setLotSize(initialData.lotSize || '');
            setBedrooms(initialData.bedrooms || '');
            setAmenities((prevAmenities) => ({
                ...prevAmenities,
                ...initialData.amenities
            }));
        }
    }, [initialData]);

    const handleAmenityChange = (amenity) => {
        setAmenities(prevState => ({
            ...prevState,
            [amenity]: !prevState[amenity]
        }));
    };

    const validateFields = () => {
        return yearBuilt && lotSize
    };

    const Next = () => {
        if (!validateFields()) {
            message.error('Please Provide all fields.');
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setHighlight(true);
            setNeighborhood(false);
        }, 1000)
        const formData = {
            yearBuilt,
            lotSize,
           
            amenities
        };

        handleDataUpdate(formData);
    };

    return (
        <div className="highlightSection">
            <div className="hightlightInnerDiv">
                <div className="stepsCount">
                    <span>step 03 / 07</span>
                </div>
                <h1 className='infoHeading'>Highlight the Charm—Show Off What Makes This Property Unique.</h1>
                <div className="createVideoSectionIneerDiv">
                    <div className='inputsDiv'>
                        <div className="mb-6 marginInput">
                            <label htmlFor="year-built" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Year Built</label>
                            <input
                                type="number"
                                id="year-built"
                                placeholder="1995"
                                value={yearBuilt}
                                onChange={(e) => setYearBuilt(e.target.value)}
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                            />
                        </div>
                    </div>
                    <div className='inputsDiv'>
                        <div className="mb-6">
                            <label htmlFor="lot-size" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Lot Size</label>
                            <input
                                type="text"
                                id="lot-size"
                                placeholder="0.5 acres"
                                value={lotSize}
                                onChange={(e) => setLotSize(e.target.value)}
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                            />
                        </div>
                    </div>
                </div>

                {/* Indoor Amenities */}
                <div className="HomeAmenitiesCheckbox">
                    <h3>Indoor Amenities</h3>
                    <div className='checkboxContainer'>
                        <Checkbox className='checkbox' checked={amenities.fireplace} onChange={() => handleAmenityChange('fireplace')}>
                            <span className='checkboxname'>Fireplace</span>
                        </Checkbox>
                        <Checkbox checked={amenities.highCeilings} onChange={() => handleAmenityChange('highCeilings')}>
                            <span className='checkboxname'>High Ceilings</span>
                        </Checkbox>
                        <Checkbox checked={amenities.hardwoodFloors} onChange={() => handleAmenityChange('hardwoodFloors')}>
                            <span className='checkboxname'>Hardwood Floors</span>
                        </Checkbox>
                        <Checkbox checked={amenities.largeWindows} onChange={() => handleAmenityChange('largeWindows')}>
                            <span className='checkboxname'>Large Windows/Natural Light</span>
                        </Checkbox>
                        <Checkbox checked={amenities.walkInCloset} onChange={() => handleAmenityChange('walkInCloset')}>
                            <span className='checkboxname'>Walk-in Closet</span>
                        </Checkbox>
                        <Checkbox checked={amenities.homeOffice} onChange={() => handleAmenityChange('homeOffice')}>
                            <span className='checkboxname'>Home Office</span>
                        </Checkbox>
                    </div>
                </div>

                {/* Kitchen Amenities */}
                <div className="HomeAmenitiesCheckbox">
                    <h3>Kitchen Amenities</h3>
                    <div className='checkboxContainer'>
                        <Checkbox checked={amenities.stainlessSteelAppliances} onChange={() => handleAmenityChange('stainlessSteelAppliances')}>
                            <span className='checkboxname'>Stainless Steel Appliances</span>
                        </Checkbox>
                        <Checkbox checked={amenities.kitchenIsland} onChange={() => handleAmenityChange('kitchenIsland')}>
                            <span className='checkboxname'>Kitchen Island</span>
                        </Checkbox>
                        <Checkbox checked={amenities.graniteCountertops} onChange={() => handleAmenityChange('graniteCountertops')}>
                            <span className='checkboxname'>Granite/Quartz Countertops</span>
                        </Checkbox>
                        <Checkbox  className='checkboxmarble'checked={amenities.marbleCountertops} onChange={() => handleAmenityChange('marbleCountertops')}>
                            <span className='checkboxname'>Marble Countertops</span>
                        </Checkbox>
                    </div>
                </div>

                {/* Outdoor Amenities */}
                <div className="HomeAmenitiesCheckbox">
                    <h3>Outdoor Amenities</h3>
                    <div className='checkboxContainer'>
                        <Checkbox checked={amenities.pool} onChange={() => handleAmenityChange('pool')}>
                            <span className='checkboxname'>Pool</span>
                        </Checkbox>
                        <Checkbox checked={amenities.patio} onChange={() => handleAmenityChange('patio')}>
                            <span className='checkboxname'>Patio/Deck</span>
                        </Checkbox>
                        <Checkbox checked={amenities.outdoorKitchen} onChange={() => handleAmenityChange('outdoorKitchen')}>
                            <span className='checkboxname'>Outdoor Kitchen/Barbecue Area</span>
                        </Checkbox>
                        <Checkbox className='checkboxmarble' checked={amenities.privateYard} onChange={() => handleAmenityChange('privateYard')}>
                            <span className='checkboxname'>Private Yard/Garden</span>
                        </Checkbox>
                        <Checkbox checked={amenities.balcony} onChange={() => handleAmenityChange('balcony')}>
                            <span className='checkboxname'>Balcony</span>
                        </Checkbox>
                    </div>
                </div>

                {/* Community Amenities */}
                <div className="HomeAmenitiesCheckbox">
                    <h3>Community Amenities</h3>
                    <div className='checkboxContainer'>
                        <Checkbox checked={amenities.fitnessCenter} onChange={() => handleAmenityChange('fitnessCenter')}>
                            <span className='checkboxname'>Fitness Center</span>
                        </Checkbox>
                        <Checkbox checked={amenities.clubhouse} onChange={() => handleAmenityChange('clubhouse')}>
                            <span className='checkboxname'>Clubhouse</span>
                        </Checkbox>
                        <Checkbox checked={amenities.communityPool} onChange={() => handleAmenityChange('communityPool')}>
                            <span className='checkboxname'>Community Pool</span>
                        </Checkbox>
                        <Checkbox checked={amenities.security} onChange={() => handleAmenityChange('security')}>
                            <span className='checkboxname'>24-Hour Security</span>
                        </Checkbox>
                    </div>
                </div>

                {/* Garage/Parking Amenities */}
                <div className="HomeAmenitiesCheckbox">
                    <h3>Garage/Parking Amenities</h3>
                    <div className='checkboxContainer'>
                        <Checkbox checked={amenities.attachedGarage} onChange={() => handleAmenityChange('attachedGarage')}>
                            <span className='checkboxname'>Attached Garage</span>
                        </Checkbox>
                        <Checkbox checked={amenities.evChargingStation} onChange={() => handleAmenityChange('evChargingStation')}>
                            <span className='checkboxname'>Electric Vehicle Charging Station</span>
                        </Checkbox>
                    </div>
                </div>

                {/* Technology & Utilities Amenities */}
                <div className="HomeAmenitiesCheckbox">
                    <h3>Technology & Utilities</h3>
                    <div className='checkboxContainer'>
                        <Checkbox checked={amenities.smartHomeSystem} onChange={() => handleAmenityChange('smartHomeSystem')}>
                            <span className='checkboxname'>Smart Home System</span>
                        </Checkbox>
                        <Checkbox checked={amenities.homeSecuritySystem} onChange={() => handleAmenityChange('homeSecuritySystem')}>
                            <span className='checkboxname'>Home Security System</span>
                        </Checkbox>
                        <Checkbox checked={amenities.solarPanels} onChange={() => handleAmenityChange('solarPanels')}>
                            <span className='checkboxname'>Solar Panels</span>
                        </Checkbox>
                    </div>
                </div>

                <div className="nextButton" onClick={Next}>
                    {loading ? <Spin className="custom-spin" /> : 'Next'}
                </div>
            </div>
        </div>
    );
};

export default Highlight;
