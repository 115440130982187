import { Dropdown, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import paths from '../../Config/paths';

const MenuDropDown = () => {
    const history = useHistory();
    const location = useLocation()
    const menu = (
        <Menu>
            <Menu.Item
                className={location.pathname === paths?.CONTACT_US ? 'active-menu-text' : 'menu-text'} 
                onClick={() => history.push(paths?.CONTACT_US)}
            >
                Contact Us
            </Menu.Item>
            <Menu.Item
                className={location.pathname === paths?.PRIVACY_POLICY ? 'active-menu-text' : 'menu-text'} 
                onClick={() => history.push(paths?.PRIVACY_POLICY)}
            >
                Privacy Policy
            </Menu.Item>
            <Menu.Item
                className={location.pathname === paths?.COPYRIGHT_POLICY ? 'active-menu-text' : 'menu-text'} 
                onClick={() => history.push(paths?.COPYRIGHT_POLICY)}
            >
                Copyright Policy
            </Menu.Item>
            <Menu.Item
                className={location.pathname === paths?.TERMS_CONDITIONS ? 'active-menu-text' : 'menu-text'} 
                onClick={() => history.push(paths?.TERMS_CONDITIONS)}
            >
                Terms & Conditions
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['hover']}>
            <div className="dropdownTrigger" style={{ cursor: 'pointer', padding: '10px', display: 'inline-block' }}>
                <MenuOutlined />
            </div>
        </Dropdown>
    );
}

export default MenuDropDown;
