import React, { useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import { message, Spin } from 'antd'; // Ensure Spin is imported if you're using Ant Design
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'; // Import the icons
import { AUTH } from '../../utils/apis';
import { Link, useHistory } from 'react-router-dom'
import paths from '../../Config/paths';

const ForgotPassword = () => {
    const history = useHistory()
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleForgot = async () => {
        if (newPassword.length < 6) {
            message.error('Password must be at least 6 characters long.');
            setLoading(false);
            return; 
        }
    
        setLoading(true);
        try {
            const response = await axios.post(AUTH?.FORGOT_PASSWORD, { email, newPassword });
            console.log(response.data);
            message.success('Password has been updated successfully.');
            history.push(paths?.LOGIN)
        } catch (err) {
            message.error(err.response?.data.message || 'Something went wrong, please try again.'); // Ensure this is reached
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='card-parent-wrapper'>
            <div className='cardFlexDiv'>
                <div className='card-parent'>
                    <div className='card'>
                        <div className="signupHeading">
                            <h1>Forgot Password</h1>
                        </div>
                        <div className="signupInpu">
                            <div className="mb-6 marginInput">
                                <label htmlFor="email" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-6 marginInput">
                                <label htmlFor="newPassword" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? 'text' : 'password'} // Toggle input type based on showPassword
                                        id="newPassword"
                                        placeholder="New Password"
                                        className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0 pr-10" // Add padding-right for the icon
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                    <span
                                        onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                    >
                                        {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                    </span>
                                </div>
                            </div>
                            <div className='SignupButton'>
                                <button type="button" onClick={handleForgot} disabled={loading}>
                                    {loading ? <Spin size="small" style={{ marginRight: 8 }} /> : 'Update Password'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
