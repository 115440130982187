import React, { useState, useEffect } from 'react';
import { CreateVideo, Essentials, Highlight, Uploader, Neighborhood, Financials, GenerateVideo, Progress, VideoPreview } from '../../Components';
import { io } from 'socket.io-client';

const GeneratingVideo = () => {
  const [createVideo, setCreateVideo] = useState(false);
  const [essentials, setEssentials] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [neighborhood, setNeighborhood] = useState(false);
  const [financials, setFinancials] = useState(false);
  const [generateVideo, setGenerateVideo] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(1);
  const [uploader, setUploader] = useState(false);
  const [formData, setFormData] = useState({
    createVideo: null,
    essentials: null,
    highlight: null,
    neighborhood: null,
    financials: null,
    generateVideo: null,
    uploader: null,

  });

  useEffect(() => {
    const socket = io('/');
    setSocket(socket);
    socket.on('progress', (data) => {
      setProgress(data.progress);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleDataUpdate = (step, data) => {
    setFormData(prevData => {
      const newData = {
        ...prevData,
        [step]: data,
      };
      // console.log('Updated formData:', newData);
      return newData;
    });
  };

  const resetProgress = () => {
    setProgress(1);
  };

  return (
    <div>
      {!createVideo ? (
        <CreateVideo
          setCreateVideo={setCreateVideo}
          handleDataUpdate={(data) => handleDataUpdate('createVideo', data)}
          initialData={formData.createVideo}
        />
      ) : !essentials ? (
        <Essentials
          setEssentials={setEssentials}
          setHighlight={setHighlight}
          handleDataUpdate={(data) => handleDataUpdate('essentials', data)}
          initialData={formData.essentials}
        />
      ) : !highlight ? (
        <Highlight
          setHighlight={setHighlight}
          setNeighborhood={setNeighborhood}
          handleDataUpdate={(data) => handleDataUpdate('highlight', data)}
          initialData={formData.highlight}
        />
      ) : !neighborhood ? (
        <Neighborhood
          setNeighborhood={setNeighborhood}
          setFinancials={setFinancials}
          handleDataUpdate={(data) => handleDataUpdate('neighborhood', data)}
          initialData={formData.neighborhood}
        />
      ) : !financials ? (
        <Financials
          setFinancials={setFinancials}
          setGenerateVideo={setGenerateVideo}
          setUploader={setUploader}
          handleDataUpdate={(data) => handleDataUpdate('financials', data)}
          initialData={formData.financials}
        />
      ) : !uploader ? (
        <Uploader
          setUploader={setUploader}
          setGenerateVideo={setGenerateVideo}
          handleDataUpdate={(data) => handleDataUpdate('uploader', data)}
          initialData={formData.uploader}
        />
      ) : !generateVideo ? (
        <GenerateVideo
          socket={socket}
          setShowVideoPreview={setShowVideoPreview}
          setVideoPreviewUrl={setVideoPreviewUrl}
          setCreateVideo={setCreateVideo}
          resetProgress={resetProgress}
          setGenerateVideo={setGenerateVideo}
          formData={formData}
          setShowProgress={setShowProgress}
          handleDataUpdate={(data) => handleDataUpdate('generateVideo', data)}
        />
      ) : !showProgress ? (
        <Progress
          setShowProgress={setShowProgress}
          progress={progress}
        />
      ) : !showVideoPreview ? (
        <VideoPreview
          videoPreviewData={videoPreviewUrl} 
          setShowVideoPreview={setShowVideoPreview}
          setCreateVideo={setCreateVideo}
          setEssentials={setEssentials}
          setHighlight={setHighlight}
          setNeighborhood={setNeighborhood}
          setFinancials={setFinancials}
          setGenerateVideo={setGenerateVideo}
          setShowProgress={setShowProgress}
          formData={formData}
          setUploader={setUploader}
        />
      ) : null}
    </div>

  );
};

export default GeneratingVideo;
