const LANDING_PAGE = '/'
const HOME = '/home'
const LOGIN = '/login'
const SIGNUP = '/register'
const PROJECT = '/projects'
const CREATE_VIDEO = '/create-video'
const TEMPLATES ='/templates'
const PREVIEW = '/preview'
const SETTINGS = '/setting'
const FORGOT_PASSWORD = '/forgot-password'
const EMAIL_VERIFICATION = '/email-verification'
const PRIVACY_POLICY = '/privacy-policy'
const COPYRIGHT_POLICY = '/copyright-policy'
const TERMS_CONDITIONS = '/terms-&-conditions'
const CONTACT_US = '/contact-us'

export default {
    HOME,
    LOGIN, 
    SIGNUP,
    PROJECT,
    CREATE_VIDEO,
    TEMPLATES,
    LANDING_PAGE,
    PREVIEW,
    SETTINGS,
    FORGOT_PASSWORD,
    EMAIL_VERIFICATION,
    PRIVACY_POLICY,
    COPYRIGHT_POLICY,
    TERMS_CONDITIONS,
    CONTACT_US
}