import React from 'react'
import { RecentVideos } from '../../utils/constants'
const Templates = () => {
    return (
        <div className="recentVideos">
            <div className='NewProjectButton'>
                <button
                >
                    Templates
                </button>
            </div>
            <div className="recentVideosInnerDiv">
                <h1>Recent Projects</h1>
                <div className="videosSection">
                    <div className="videosInnerSection">
                        {
                            RecentVideos.map((v, i) => {
                                return (
                                    <>
                                        <div className="recentVideo">
                                            <img src={v?.img} alt="" className='recentVideoImg' />
                                            <div className="videosText">
                                                <h1>{v?.heading}</h1>
                                                <p>{v?.paragraph}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className="videosInnerSection">
                        {
                            RecentVideos.map((v, i) => {
                                return (
                                    <>
                                        <div className="recentVideo">
                                            <img src={v?.img} alt="" className='recentVideoImg' />
                                            <div className="videosText">
                                                <h1>{v?.heading}</h1>
                                                <p>{v?.paragraph}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Templates
