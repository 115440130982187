import React from 'react'
import { MenuDropDown, Footer } from '../../Components'
import logo from '../../Assets/Image/logo.png'
import { ArrowBack } from '@mui/icons-material'
import paths from "../../Config/paths"
import { useHistory, useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
    const history = useHistory()
    return (
        <div>
            <div className='SignUpHeader logoHeaderDiv'>
                <div className="logoVideoAi mobileheaderLogo">
                    <img className='logo' src={logo} alt="" />
                </div>
                <div>
                    <MenuDropDown />
                </div>
            </div>
            <div className="go-back-icon">
                <span onClick={() => { history?.push(paths?.LANDING_PAGE) }}> <ArrowBack /> </span>
            </div>
            <div className='privacy-policy-main-div'>
                <div className='inner-privacy-policy'>
                    <h1 className='main-privacy-heading'>Privacy Policy</h1>
                </div>
                <div className='inner-privacy-div'>
                    <p>Your privacy is important to us at Real Visiony Corp (“Company”, “we”, “us”, or “our”). As such, we have a few fundamental principles:</p>
                    <ol className='privaciy-list'>
                        <li>We don’t and won’t ask you for personally identifying information unless we truly need it. To make this Privacy Policy easier to read, RealVisionAI a subsidiary of FinanceCratey.ai, and our products and services are collectively called the “Services” throughout this policy.</li>
                        <li>We don’t share your personally identifying information, with anyone, except as needed, to provide service support, to comply with applicable law, or to protect our rights.</li>
                        <li>You are in control of the information you share through the Services and any customer data you provide is your responsibility. As part of our
                            <span>
                                <em>Terms of Service</em>,</span>
                            we require that you respect other users of our services and your own customer’s rights to privacy in the same manner you’d require your data to be respected.</li>
                    </ol>
                    <p>The Services may not require you to enter any information to view; however, to access or otherwise utilize certain features of the Services, you will be asked to create an account. If you create an account, certain limited information which generally includes personal information will be required to deliver the services to you. <em>Such information shall be governed by this policy.</em></p>
                    <p>Please note, that this Privacy Policy does not apply to the privacy practices of third parties whose links and retail products may appear on or through the Services. We are not responsible for the content or privacy practices of third parties. We recommend that you exercise caution before you voluntarily disclose personally identifiable information to other users or through third-party links on third-party websites or platforms.</p>
                    <h4 className='privacy-h4'>Who Does this Privacy Policy Apply To?</h4>
                    <p>This Privacy Policy applies to Users who have created an account and accepted our Terms of Use, as well as those who are viewing our Services and neither created an account nor accepted our Terms of Use. Collectively throughout this policy we use the term “you” to reference each type of user who interacts with us.</p>
                    <h4 className='privacy-h4'>Type of Information Collected and Stored.&nbsp;</h4>
                    <p><em>We process the following types of data in accordance with this policy:</em></p>
                    <ol className='privaciy-list'>
                        <li>Name and email address for Users (or prospective Users) of our services; this includes inputs provided during account creation or the contact us form.
                        </li>
                        <li>Technical data associated with the users who interact with our application through the Services we provide to customers. The data generally excludes personally identifiable information.
                        </li>
                        <li>Our production data is processed and hosted in the United States on a self serve platform.
                        </li>
                    </ol>
                    <h4 className='privacy-h4'>Collection of Data and Use of Information.&nbsp;</h4>
                    <p><strong>1. Account Creation.</strong></p>
                    <p>To use or otherwise access certain products and Services, you will need to set up an account in accordance with our Terms of Use. To set up an account, we will require certain identifying information, such as name and email that will be linked to your account. This information will be governed by the Policy.</p>
                    <p><strong>2. Email Addresses.</strong></p>
                    <p>We do not and will not send spam, sell, or rent your email address or any social media logins to third parties. We do not disclose, sell, share, trade or give away a user’s personal information to third parties, except where a user has otherwise opted-in to marketing (see below), if applicable.</p>
                    <p><strong>3. Marketing.</strong></p>
                    <p>We may contact you with marketing and promotional information (in accordance with your marketing preferences) about any services that we offer and to send you information regarding us. See below on contacting us to modify or delete your information. &nbsp;</p>
                    <p><strong>4. Logging Statistics.</strong></p>
                    <p>Like most website and browser extension operators, our servers automatically collect certain types of non-personally identifying, technical information, such as the browser type, language preference, referring site, and the date and time of each visitor request. This includes information such as:</p>
                    <ul className='privaciy-ul-list'>
                        <li>What portions of our website you access;</li>
                        <li>How long you stay for; and</li>
                        <li>What kind of device you’re using</li>
                        <li>What services you prefer accessing</li>
                        <li>How you use the services and related usage information</li>
                    </ul>
                    <p>We use this information to better understand how our visitors use our website and browser extension and to improve and maintain our Services.</p>
                    <p><strong>5. Location Information &amp; IP Addresses.</strong></p>
                    <p>We collect very limited personal data. &nbsp;If you fill out a form or create an account with your name and email address, we do link the IP address and device information to you. Additionally, we collect IP address and convert it to location for (1) security monitoring (2) supporting some features, such as IP whitelisting, country level location whitelisting, and (3) delivering location-based help and website details.</p>
                    <p><strong>6. Locale Preferences and Device Information.</strong></p>
                    <p>When you use our Services, we may automatically collect certain information from your device, its software, and your activity using our Services. This may include information you search for on our website, locale preferences, identification numbers associated with your devices, your mobile carrier, date and time stamps associated with transactions, metadata, your Internet Service Provider, files viewed on our site, operating system, and clickstream data.</p>
                    <p><strong>7. Data Aggregation.</strong></p>
                    <p>In addition to the other uses described in this policy, you agree that we may extract and use information from the information you disclose for the purposes of aggregating data in a non-identifiable method. This aggregated data may be used internally to improve services or without limitation, to develop, analyze, combine, or publish the aggregated data for commercial purposes.</p>
                    <p><strong>8. Cookies.</strong></p>
                    <p>Your use of certain services may result in the assignment and storage of session cookies and analytics tools to recognize your access privileges and generally track user preferences. A cookie is a text file that is placed on the hard disk of your computer or mobile device by a server. Session cookies expire when you end your session and close your browser interface. Cookies cannot be used to run programs or deliver viruses to your computer or mobile device. Cookies are uniquely assigned to you and can only be read by a server in the domain that issued the cookie to you. Visitors who do not wish to have cookies placed on their computers or mobile devices should deny Cookies by configuring their respective browsers to do so. If Cookies are denied, certain features of our Services may not function properly.</p>
                    <p><strong>9. How We Share Your Information.</strong></p>
                    <p>We take all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally identifying information.</p>
                    <p>We disclose potential personally identifying information only on an as needed (or required) basis as follows:</p>
                    <ul className='privaciy-ul-list'>
                        <li>To employees that: (i) need to know that information in order to process it on our behalf or to provide the services; and (ii) that have expressly agreed not to disclose it to others. Note* Some of those employees, and contractors may be located outside of your home country; by using the services you consent to the transfer of such information to them.</li>
                        <li>As required by law such to comply with a subpoena or similar legal process. To the extent we are legally permitted to do so, we will take commercially reasonable steps to notify you in the event that we are required to provide your personal information to third parties as part of a legal process.</li>
                        <li>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a written government request.</li>
                        <li>In the event of a merger, acquisition, or any form of sale of some or all of our assets, we will ensure that the acquiring organization agrees to protect personal information in accordance with the commitments we have made in this Privacy Policy, and that the acquiring organization will provide notice before personal information, customer information, or business information becomes subject to a different privacy policy.</li>
                        <li>To any other third party with your prior consent to do so. We do not sell your personal information to third parties.</li>
                    </ul>
                    <h4 className='privacy-h4'>ACCESSING AND UPDATING YOUR PERSONAL INFORMATION.</h4>
                    <p>To review, access or update your personal information, review its accuracy, or request deletion please contact us at support@financecrate.com. We will make commercially reasonable efforts to provide you with reasonable access to any of your personal or other account information we maintain within forty-five (45) days of your request. We provide this access so you can review, make corrections, or request deletion of your information. If we cannot honor your request within the 45-day period, we will tell you when we will be able to provide access. In the unlikely event that we cannot provide you access to your information, we will explain why we cannot do so.</p>
                    <p>Please note that any changes you make will be reflected in our active user database within a reasonable time; however, we may retain information you submit for backups, archiving, prevention of fraud, to satisfy legal obligations, or otherwise we reasonably believe there is a legitimate reason to do so.</p>
                    <h4 className='privacy-h4'>THIRD PARTY SERVICES.</h4>
                    <p>We use various third-party services to create the best experience for users and for improving existing products and services. Third party services use cookies or similar technologies to analyze trends, administer the website, track users’ movements around the website, and to gather demographic information about our user base as a whole. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service. We use third-party services, such as Amazon web services, Azure, and Google Cloud, to store information, host or our services, manage accounts, process payments, etc. In all instances we manage the transfer of such data by transferring personal information in encrypted formats. We also use various third-party services that collect and analyze information about your use of our site and to ensure user success and improved relationship management. A list of third party services we use to operate and deliver our services is available upon request.</p>
                    <h4 className='privacy-h4'>SECURITY, DATA RETENTION, AND STORAGE.</h4>
                    <p>To prevent unauthorized access, safeguard data accuracy, and maintain the appropriate use of information, we have put in place appropriate physical, technical, and administrative procedures to protect the personal information data you submit. We make every effort to ensure the integrity and security of our network and systems. However, since the Internet is not 100% secure and as new technology evolves and emerges, we cannot guarantee that our security measures will prevent third-party interferences from illegally obtaining or tampering with your personal information.</p>
                    <p>We encourage you to help us by also taking precautions to protect your personal data when you use the services.</p>
                    <p>We will retain your personal information for as long as needed for the purposes described above and/or as required by law. A user may request access to certain data about themselves by emailing support@financecrate.com.</p>
                    <h4 className='privacy-h4'>EXTENDING CCPA TO ALL USERS.</h4>
                    <p>The California Consumer Privacy Act (CCPA) and some other state laws provide you with fundamental rights. Due to our commitment to privacy, we have extended those data subject rights to persons in all jurisdictions.&nbsp;&nbsp;</p>
                    <strong>1. Right to be forgotten (“Right to Erasure”)</strong>
                    <p> This right provides you with the ability to ask for the deletion of your data. This will generally apply to situations where a customer relationship has ended. It is important to note that this is not an absolute right and depends on our retention schedule and retention period in line with other applicable laws.</p>
                    <strong>2. Right to know how we are processing your information (“Right to Information”)</strong>
                    <p>This right provides that you may ask us for information about what personal data is being processed and the rationale for such processing. For example, you may ask for the list of processors with whom we share your personal data.</p>
                    <strong>3. Right to view the information we have collected about you (“Right to Access”)</strong>
                    <p>This right provides you with the ability to get access to your personal data that is being processed. This request provides you with the right to see or view your own personal data, as well as to request copies of the personal data.</p>
                    <strong>4. Right to rectification</strong><p>This right provides you with the ability to ask for modifications to your personal data in case you believe that this personal data is not up to date or not accurate.</p>
                    <strong>5. Right to withdraw consent</strong><p>This right provides you with the ability to withdraw a previously given consent for processing of your personal data for a purpose.</p>
                    <strong>6. Right to object to processing your information (“Right to object”)</strong><p>This right allows you to object to certain types of data processing. These are:</p>
                    <ul className='privaciy-ul-list'>
                        <li>Direct marketing</li>
                        <li>The processing of personal data for statistical purposes related to historical or scientific research</li>
                        <li>The processing of data for tasks in the public interest</li>
                        <li>The exercising of official authority invested in you</li>
                        <li>Objections to data processing in yours or a third party’s legitimate interest</li>
                        <li>Objections to data processing based on your own beliefs and situations</li>
                    </ul>
                    <strong>7. Right to data portability</strong>
                    <p>This right provides you with the ability to ask for transfer of your personal data. As part of such request, you may ask for your personal data to be provided back to you in a machine-readable electronic format or, if technically feasible, transferred to another service provider.</p>
                    <strong>8. Right to Limit Use and Disclosure of Sensitive Personal Information</strong><p>You have the right, at any time, to direct us (as a business that collects sensitive personal information about you) to limit our use of your sensitive personal information to that use which is necessary to perform the services expected.&nbsp;</p>
                    <p>
                        <strong>
                            <em>
                                <u>If you would like to exercise any of the above rights, please contact our support team at </u>
                            </em>
                        </strong>
                        <strong>
                            <em>
                                <u>support@3.ai</u>
                            </em>
                        </strong>
                    </p>
                    <h4 className='privacy-h4'>ADDITIONAL INFORMATION, CHANGES AND CONTACTING US</h4>
                    <p><strong>1. Communications.</strong></p>
                    <p>If you have an account with us for access and use of our Services, we may send you information about feature updates, events, educational opportunities, pricing, promotions, etc. You may opt out of notifications at any time through your settings on our website. We may also occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with our Services. You may unsubscribe from our newsletters at any time by selecting the option in the email or contacting us.</p>
                    <p><strong>2. Feedback &amp; Support.</strong></p>
                    <p>If you send us a request (via a support email or one of our feedback channels), We reserve the right to publish it (stripped of all personally identifying information, of course) in order to help us clarify or respond to your request or to help us support other Users.
                    </p>
                    <p>We may provide technical support to service your account with us. In order to do so, we may use certain personally identifying information, with your consent, to access your account for the purpose of troubleshooting, running tests, and/or otherwise providing support. In providing technical support to you, we may potentially see other personally identifying information viewable on your account pages. As with all other information, we promise to hold any information we encounter in the process of providing support to the highest possible security and protection standards.</p>
                    <p><strong>3. Privacy Of Minors.</strong></p>
                    <p>We do not promote or offer the Services for use by anyone under the age of 13 (“minors”). We do not knowingly solicit or collect personal information from minors, and we will not knowingly link to any third-party website or platform, or host any customer sites that solicit or collect personal information from minors. If you believe that a minor has disclosed personal information to us or that we have linked to such a third-party or user website or platform, please contact us at support@financecrate.com.</p>
                    <p><strong>4. Additional Information for International Users.</strong></p>
                    <p>If you are visiting this website and/or accessing the Services from outside the United States, please be aware that you are sending information (including Personal Information) to the United States where our servers are located. This information may be transferred within the United States or back out of the United States to other countries outside of your country of residence, depending on the type of information and how its stored by us. These countries (including the United States) may not necessarily have data protection laws as comprehensive or protective as those in your country of residence, however, our collection, storage and use of your personal information will at all times be governed by this Privacy Policy.</p>
                    <p><strong>5. Business Transfers.</strong></p>
                    <p>If the ownership of RealVisionAI a subsidiary of FinanceCratey Corp Inc. &nbsp;substantially changes, such that all of its assets were acquired, or merged into another entity, or in the unlikely event that we enter bankruptcy, you understand that any stored personally identifying, and non-personally identifying information and data will likely be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer or merging entity of the Company may continue to use your personal information as set forth in this policy.</p>
                    <p><strong>6. Privacy Policy Changes.</strong></p>
                    <p>We may update this Privacy Policy to reflect changes to our information practices. If we make any change in how we use your personal data we will notify you by email (specified upon registration), and/or we will notify you by means of a notice on this page prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
                    <p><strong>7. Contact Us. &nbsp;&nbsp;</strong></p>
                    <p>If you have any questions or suggestions regarding this Privacy Policy, please contact us via email at support@financecrate.com.</p>
                    <p><strong>8. Contact the Appropriate Authority.</strong></p>
                    <p>Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you have the right to contact a regulatory body or data protection authority in relation to your complaint.&nbsp;</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
