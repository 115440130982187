import React, { useState, useEffect } from 'react';
import { Menu, Drawer } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { IoMdAdd, IoIosLogOut, IoMdNotificationsOutline, IoIosArrowDown } from "react-icons/io";
import { drawerRoutes } from '../../utils/constants';
import { IoMenuSharp } from "react-icons/io5";
import { UserLogo } from '../../Assets';
import paths from '../../Config/paths';
import logo from '../../Assets/Image/logo.png';
import { removeUser } from '../../Redux/actions/authActions';

function MobileMenu({ prop }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = window.location.pathname;
    const userData = useSelector((state) => state.auth.loginData);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getActiveMenuIndex = (pathname) => {
        return drawerRoutes.findIndex(route => route.route === pathname);
    };

    const [activeMenuIndex, setActiveMenuIndex] = useState(getActiveMenuIndex(location));

    const toggleDrawer = (open) => () => {
        setDrawerVisible(open);
    };

    const handleMenuClick = (route, index) => {
        setActiveMenuIndex(index);
        history.push(route);
        setDrawerVisible(false);
    };

    const list = () => (
        <Menu
            defaultOpenKeys={[`${activeMenuIndex}`]}
            defaultSelectedKeys={[`${activeMenuIndex}`]}
            style={{ width: 250 }}
            mode='inline'
        >
            <div className='CreateVideoButton' onClick={() => history.push(paths?.CREATE_VIDEO)}>
                <div><IoMdAdd size={20} /></div>
                <div className='CreateVideoName'>Create Video</div>
            </div>
            {drawerRoutes.map((v, i) => (
                <Menu.Item
                    key={i}
                    icon={React.cloneElement(v.icon, {
                        style: { color: activeMenuIndex === i ? 'white' : 'black' }
                    })}
                    className="custom-menu-item"
                    style={{ marginBottom: '20px' }}
                    onClick={() => handleMenuClick(v.route, i)}
                >
                    <span className='SidemenuTitle' style={{ color: activeMenuIndex === i ? 'black' : '#858585' }}>
                        {v.title}
                    </span>
                </Menu.Item>
            ))}
            <div className="mobileLogout" onClick={() => history.push(paths?.LOGIN)}
            >
                <div className="logouticon" onClick={() => {
                    dispatch(removeUser()); // Ensure this function is correctly defined and imported
                    history.push(paths?.LOGIN);
                }}>
                    <span><IoIosLogOut /></span>
                </div>
                <div>
                    Log Out
                </div>
            </div>

        </Menu>
    );

    return (
        <div>
            <div className="mainHeaderDiv">
                <div className="headerInnersection">
                    <div className="headerLogoSection mobileLogoSection">
                        <div className='toggleIcon'>
                            {isMobile && (
                                <button className='menu_btn' onClick={toggleDrawer(true)}>
                                    <IoMenuSharp className='menu_icon' size={20} />
                                </button>
                            )}
                            {!isMobile && (
                                <div className='desktop-sidebar'>
                                    {list()}
                                </div>
                            )}
                            <Drawer
                                placement="left"
                                anchor={'right'}
                                open={drawerVisible}
                                onClose={toggleDrawer(false)}
                            >
                                {list()}
                            </Drawer>
                        </div>
                        <img className='logo' src={logo} alt="" />
                    </div>
                    <div className="userDetailsAndIcons">
                        <div className="iconsSection">
                            <div className="HeadrrIconDiv">
                                <IoMdAdd size={20} color='aliceblue' />
                            </div>
                            <div className="HeadrrIconDiv notificationIcon">
                                <IoMdNotificationsOutline size={20} color='#F4145C' />
                            </div>
                            <div className="UserInfo">
                                <div className="userLogoImgDiv">
                                    <div>
                                        <img src={UserLogo} alt="" className='userLogoImg' />
                                    </div>
                                    <div className='usernameAndEmail'>
                                        <span className='user-name'>{userData.displayName}</span>
                                        <p className='user-email margin-bottom-0'>{userData.email}</p>
                                    </div>
                                    <div className="downArrowIcon">
                                        <IoIosArrowDown />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default MobileMenu;
