import { Footer, MenuDropDown } from "../../Components"
import logo from '../../Assets/Image/logo.png'
import { ArrowBack } from "@mui/icons-material"
import paths from "../../Config/paths"
import { useHistory, useLocation } from 'react-router-dom';

const CopyrightPolicy = () => {
    const history = useHistory();

    return (
        <>
            <div>
                <div className='SignUpHeader logoHeaderDiv'>
                    <div className="logoVideoAi mobileheaderLogo">
                        <img className='logo' src={logo} alt="" />
                    </div>
                    <div>
                        <MenuDropDown />
                    </div>
                </div>
                <div className="go-back-icon">
                    <span onClick={() => { history?.push(paths?.LANDING_PAGE) }}> <ArrowBack /> </span>
                </div>
                <div className='privacy-policy-main-div'>
                    <div className='inner-privacy-policy'>
                        <h1 className='main-privacy-heading'>RealVisionAI a subsidiary of FinanceCrate Copyright Policy</h1>
                    </div>
                    <div className='inner-privacy-div'>
                        <p>Notification of Copyright Infringement</p>
                        <p>RealVisionAI a subsidiary of FinanceCrate, Corp. (“RealVisionAI a subsidiary of FinanceCrate”) respects the intellectual property rights of others and expects its users to do the same. It is RealVisionAI a subsidiary of FinanceCrate’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the accounts of users who repeatedly infringe the copyrights of others.</p>
                        <p>In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website at https://www.copyright.gov/legislation/dmca.pdf, RealVisionAI a subsidiary of FinanceCrate will respond expeditiously to claims of copyright infringement committed using the RealVisionAI a subsidiary of FinanceCrate website or other online network accessible through a mobile device or other type of device (the “Sites”) that are reported to RealVisionAI a subsidiary of FinanceCrate’s Designated Copyright Agent, identified in the sample notice below.</p>
                        <p>If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Sites by completing the following DMCA Notice of Alleged Infringement and delivering it to RealVisionAI a subsidiary of FinanceCrate’s Designated Copyright Agent. Upon receipt of the Notice as described below, RealVisionAI a subsidiary of FinanceCrate will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from the Sites.</p>
                        <p>DMCA Notice of Alleged Infringement (“Notice”)</p>
                        <ol className='privaciy-list'>
                            <li>Identify the copyrighted work that you claim has been infringed, or – if multiple copyrighted works are covered by this Notice – you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
                            <li>&nbsp;Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Site(s) where such material may be found.</li>
                            <li>Provide your mailing address, telephone number, and, if available, email address.</li>
                            <li>Include both of the following statements in the body of the Notice:
                                <ul className='privaciy-ul-list'>
                                    <li>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”</li>
                                    <li>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
                                </ul>
                            </li>
                            <li>Provide your full legal name and your electronic or physical signature.</li>
                        </ol>
                        <span>Deliver this Notice, with all items completed, to RealVisionAI a subsidiary of FinanceCrate’s Designated Copyright Agent: </span>
                        <span>Vikram Chalana </span>
                        <span>c/o RealVisionAI a subsidiary of FinanceCrate, Corp</span>
                        <span>17625 48th Ave SE, Bothell, WA 98012</span>
                        <span>support@financecrate.com RealVisionAI a subsidiary of FinanceCrate.ai</span>
                        <span>+1-855-892-6167</span>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default CopyrightPolicy