import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { MdOutlineLoop } from "react-icons/md";
import { LuDownload } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaSave, FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import { message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { POST } from '../../utils/apis';
import paths from '../../Config/paths';
const VideoPreview = ({ videoPreviewData, setCreateVideo, setEssentials, setHighlight, setNeighborhood, setFinancials, setGenerateVideo, setShowProgress, setUploader, formData }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const userData = useSelector((state) => state.auth.loginData);
    const uid = userData?.uid
    const videoUrl = typeof videoPreviewData === 'string'
        ? videoPreviewData
        : videoPreviewData?.url;
    const Address = typeof videoPreviewData === 'object'
        ? (videoPreviewData?.address || videoPreviewData?.essentials?.address)
        : undefined;

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = 'video.mp4';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const Regenrate = () => {
        setCreateVideo(false)
        setEssentials(false)
        setHighlight(false)
        setNeighborhood(false)
        setFinancials(false)
        setGenerateVideo(false)
        setShowProgress(false)

        formData.createVideo = null;
        formData.essentials = null;
        formData.highlight = null;
        formData.neighborhood = null;
        formData.financials = null;
        formData.generateVideo = null;
        formData.uploader = null;
    }

    const deleteUrl = async () => {
        history.push(paths?.HOME)

        // console.log("Deleting video with URL:", videoUrl, "and UID:", uid); 
        // try {
        //     const response = await axios.post(POST.DELETEURL, {
        //         publicUrl: videoUrl,
        //         uid: uid,
        //     });

        //     // console.log(response);
        //     if (response.status === 200) {
        //         message.error(response.data.message);
        //         history.push(paths?.HOME)
        //     } else {
        //         message.error(response.data.message);
        //     }
        // } catch (error) {
        //     console.error("Error deleting video:", error.response?.data || error.message);
        //     message.error((error.response?.data?.message || error.message));
        // }
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const response = await axios.post(POST?.SAVEVIDEO, {
                finalVideo: videoUrl,
                uid: uid,
                essentials: Address,
                user: userData
            });

            if (response.status === 200) {
                message.success('Video saved successfully');
                history.push(paths?.HOME);
            }
        } catch (error) {
            console.error("Error saving video:", error);
            message.error(error.response?.data?.message || 'Error saving video');
        } finally {
            setIsSaving(false);
        }
    };

    const handleEdit = () => {
        setCreateVideo(true);
        setEssentials(false);
        setHighlight(false);
        setNeighborhood(false);
        setFinancials(false);
        setUploader(false);
        setGenerateVideo(false);
        setShowProgress(false);
    };

    // Simulate loading delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false); // Set loading to false after delay
        }, 2000); // Adjust the delay as needed

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    return (
        <div className='VideoPreviewSection' >
            <h1 className='VideoPreviewHeading'>Video Preview</h1>
            <div className="VideoPreviewInnerDiv">
                <div className="video-container">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <Spin size="large" /> {/* Show loading spinner before ReactPlayer */}
                        </div>
                    ) : (
                        <ReactPlayer
                            url={videoUrl}
                            controls={true}
                            playing={true}
                            width="100%"
                            height="100%"
                            className='reactPlayer'
                        />
                    )}
                </div>
            </div>
            <div className="VideoPreviewIcon">
                <div className='VideoPreviewIconsSection' onClick={Regenrate}>
                    <div className='VideoPreviewiconDiv'>
                        <MdOutlineLoop size={30} color='#858585' />
                    </div>
                    <span className='iconName' >
                        Regenerate
                    </span>
                </div>
                <div className='VideoPreviewIconsSection' onClick={handleDownload}>
                    <div className='VideoPreviewiconDiv'>
                        <LuDownload size={30} color='#2B7BFF' />
                    </div>
                    <span className='iconName'>
                        Download
                    </span>
                </div>
                <div className='VideoPreviewIconsSection' onClick={deleteUrl}>
                    <div className='VideoPreviewiconDiv'>
                        <RiDeleteBin6Line size={30} color='red' />
                    </div>
                    <span className='iconName'>
                        Delete
                    </span>
                </div>
                <div className='VideoPreviewIconsSection' onClick={!isSaving ? handleSave : undefined}>
                    <div className='VideoPreviewiconDiv'>
                        {isSaving ? (
                            <Spin size="small" />
                        ) : (
                            <FaSave size={30} color='#2B7BFF' />
                        )}
                    </div>
                    <span className='iconName'>
                        {isSaving ? 'Saving...' : 'Save'}
                    </span>
                </div>
                <div className='VideoPreviewIconsSection' onClick={handleEdit}>
                    <div className='VideoPreviewiconDiv'>
                        <FaEdit size={30} color='#2B7BFF' />
                    </div>
                    <span className='iconName'>
                        Edit
                    </span>
                </div>
            </div>
        </div>
    );
}

export default VideoPreview;
