import React, { useState } from 'react';
import { VideoOptions } from '../../utils/constants';
import { Spin } from 'antd';
const CreateVideo = ({ setCreateVideo, handleDataUpdate }) => {
    const [selectedDiv, setSelectedDiv] = useState(null);
    const [loading, setLoading] = useState(false); 

    const handleClick = (index) => {
        setSelectedDiv(index);
    };
    const Next = (index) => {
        if (selectedDiv !== null) {
            handleDataUpdate(VideoOptions[selectedDiv].heading);
            setLoading(true);
            setTimeout(() => {
                setLoading(false); 
                setCreateVideo(true);
            }, 1000)
        }
    };
    return (
        <div className="createVideomainDiv">
            <div className="CreateVideoInnerDiv">
                <div className="stepsCount">
                    <span>step 01 / 07</span>
                </div>
                <h2 className='infoHeading'>How Would You Like to Create Your Video?</h2>
                <div className="createVideoSectionIneerDiv">
                    {VideoOptions.map((v, index) => {
                        return (
                            <div
                                key={index}
                                className={`AddVideo ${selectedDiv === index ? 'active' : ''}`}
                                onClick={() => handleClick(index)}
                                style={{ border: selectedDiv === index ? '2px solid #0293F2' : 'none' }}
                            >
                                <div className="AddVideoInner">
                                    <div className="AddVideoImgDiv">
                                        {typeof v.icon === 'string' ? (
                                            <img src={v.icon} alt="Icon" style={{ width: 30 }} />
                                        ) : (
                                            v.icon
                                        )}
                                    </div>
                                    <p className='Addvideoheading' style={{ color: 'black' }}>{v.heading}</p>
                                    <p>{v.paragraph}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="nextButton" onClick={Next}>
                {loading ? <Spin className="custom-spin" /> : 'Next'}
                </div>
            </div>
        </div>
    );
};

export default CreateVideo;
