import React, { useState, useEffect } from 'react';
import { message, Spin, Select } from 'antd';

const Essentials = ({ setHighlight, setEssentials, initialData, handleDataUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [error, setError] = useState(null); // To handle errors

    const [essentialsData, setEssentialsData] = useState(initialData || {
        address: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        price: '',
        houseSize: '',
        lotSize: '',
        bedrooms: '',
    });

    useEffect(() => {
        const fetchStates = async () => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            setLoading(true); // Set loading state before fetching

            try {
                const response = await fetch("https://countriesnow.space/api/v0.1/countries/states", requestOptions);
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                const allStates = data?.data?.flatMap(country =>
                    country.states
                        ?.filter(state => state?.name && state?.state_code) // Filter out invalid entries
                        .map(state => ({ name: state?.name, state_code: state?.state_code }))
                );
                if (allStates?.length > 0) {
                    setStates(allStates);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchStates();
    }, []);



    const handleChange = (e, name) => {
        const value = e?.target ? e.target.value : e;
        const fieldName = e?.target ? e.target.name : name;

        setEssentialsData({
            ...essentialsData,
            [fieldName]: value,
        });
    };


    const validateFields = () => {
        const { address, address2, city, state, zipCode, price, houseSize, lotSize, bedrooms } = essentialsData;
        return address && address2 && city && state && zipCode && price && houseSize && lotSize && bedrooms;
    };

    const Next = () => {
        if (!validateFields()) {
            message.error('Please provide all fields.');
            return;
        }
        setLoading(true);

        handleDataUpdate(essentialsData);

        setTimeout(() => {
            setLoading(false);
            setEssentials(true);
            setHighlight(false);
        }, 1000)
    };

    return (
        <div className="essential">
            <div className="essentialInnerDiv">
                <div className="stepsCount">
                    <span>step 02 / 07</span>
                </div>
                <h1 className='infoHeading'>Start with the Essentials—Give Us the Basics to Set the Foundation.</h1>
                <div className="createVideoSectionInnerDiv">
                    <div className='inputsDiv'>
                        <div className="mb-6 marginInput">
                            <label htmlFor="address" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Property Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                placeholder="123 Main St, Los Angeles, CA"
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                value={essentialsData.address}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-6 marginInput">
                            <label htmlFor="address2" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Address 2</label>
                            <input
                                type="text"
                                id="address2"
                                name="address2"
                                placeholder="Apartment, suite, etc."
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                value={essentialsData.address2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-6 marginInput">
                            <label htmlFor="city" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">City</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                placeholder="Los Angeles, CA"
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                value={essentialsData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='ArchitecturalDropdown'>
                            <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">State</label>
                            <Select
                                id="state"
                                name="state"
                                value={essentialsData.state || undefined}
                                onChange={(value) => handleChange(value, 'state')}
                                className="w-full block h-10 p-4 text-gray-900 text-base state-dropdown"
                                placeholder="Select State"
                                loading={loading}
                                disabled={loading}
                                allowClear
                                showSearch
                                optionFilterProp="children"

                            >
                                {states.map((state, index) => (
                                    <Option key={index} value={state.state_code}>{state.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-6 marginInput">
                            <label htmlFor="zipCode" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Zip Code</label>
                            <input
                                type="text"
                                id="zipCode"
                                name="zipCode"
                                placeholder="Zip Code"
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                value={essentialsData.zipCode}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-6 marginInput">
                            <label htmlFor="price" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Listing Price</label>
                            <input
                                type="text"
                                id="price"
                                name="price"
                                placeholder="$750,000"
                                className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                value={essentialsData.price}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='inputsDiv'>
                        <div className="mb-6">
                            <label htmlFor="houseSize" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">House Size</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    id="houseSize"
                                    min="0"
                                    name="houseSize"
                                    placeholder="2,500"
                                    className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                    value={essentialsData.houseSize}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="lotSize" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Lot Size</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    id="lotSize"
                                    min="0"

                                    name="lotSize"
                                    placeholder="10,000"
                                    className="essentialInput block h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                    value={essentialsData.lotSize}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='ArchitecturalDropdown'>
                            <label htmlFor="bedrooms" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Number of Bedrooms</label>
                            <select
                                id="bedrooms"
                                name="bedrooms"
                                value={essentialsData.bedrooms}
                                onChange={handleChange}
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            >
                                <option value="">Select Bedrooms</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div className="nextButton" onClick={Next}>
                    {loading ? <Spin className="custom-spin" /> : 'Next'}
                </div>
            </div>
        </div>
    );
};

export default Essentials;
