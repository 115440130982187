import React, { useState } from 'react';
import axios from 'axios';
import { AUTH } from '../../utils/apis';
import { useSelector } from 'react-redux';
import { message, Spin } from 'antd';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const Security = () => {
    const userData = useSelector((state) => state.auth.loginData);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleNewPasswordVisibility = () => setNewPasswordVisible(!newPasswordVisible);
    const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);
   
    const UpdatePassword = async () => {
        if (!newPassword || !confirmNewPassword) {
            message.error('Please provide all fields');
            return;
        }
        if (newPassword !== confirmNewPassword) {
            message.error('Passwords do not match!');
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post(AUTH?.CHANGE_PASSWORD, {
                uid: userData.uid,
                newPassword: newPassword,
            });

            if (response.status === 200) {
                message.success('Password updated successfully');
            } else {
                message.error('Failed to update password');
            }
        } catch (error) {
            console.error('Error updating password:', error);
    
            if (error.response && error.response.data && error.response.data.message) {
                message.error(error.response.data.message);
            } else {
                message.error('Error updating password, please try again later.');
            }
        }
        finally {
            setLoading(false); 
        }
    };
    return (
        <div className='recentVideos'>
            <div className="SetingInner">
                <div>
                    <div className='SettingInp'>
                        <div className="mb-6 settingInput">
                            <label htmlFor="new-password-input" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">New password</label>
                            <div className="relative">
                                <input
                                    type={newPasswordVisible ? "text" : "password"}
                                    id="new-password-input"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="block w-full h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                />
                                <button
                                    type="button"
                                    onClick={toggleNewPasswordVisibility}
                                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                                >
                                    {newPasswordVisible ? <AiFillEyeInvisible className="h-5 w-5" /> : <AiFillEye className="h-5 w-5" />}
                                </button>
                            </div>
                        </div>

                        <div className="mb-6 settingInput">
                            <label htmlFor="confirm-new-password-input" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm New password</label>
                            <div className="relative">
                                <input
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    id="confirm-new-password-input"
                                    placeholder="Confirm New Password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    className="block w-full h-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:outline-none focus:border-transparent focus:ring-0"
                                />
                                <button
                                    type="button"
                                    onClick={toggleConfirmPasswordVisibility}
                                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                                >
                                    {confirmPasswordVisible ? <AiFillEyeInvisible className="h-5 w-5" /> : <AiFillEye className="h-5 w-5" />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="UpdateProfileButton" onClick={UpdatePassword} disabled={loading}>
                {loading ? <Spin size="small" style={{ marginRight: 8 }} /> : 'Update Password'}
            </div>
        </div>
    );
};

export default Security;
