import React, { useState } from 'react'
import { IoMdAdd, IoMdNotificationsOutline, IoIosArrowDown } from "react-icons/io";
import { useSelector, useDispatch } from 'react-redux'
import logo from '../../Assets/Image/logo.png'
import UserLogo from '../../Assets/Image/userLogo.jpg'

const Header = ({ prop }) => {
    const userData = useSelector((state) => state.auth.loginData);
    return (
        <div className="mainHeaderDiv">
            <div className="headerInnersection">
                <div className="headerLogoSection">
                    <img className='logo' src={logo} alt="" />
                </div>
                <div className="userDetailsAndIcons">
                    <div className="iconsSection">
                        <div className="HeadrrIconDiv">
                            <IoMdAdd size={20} color='aliceblue' />
                        </div>
                        <div className="HeadrrIconDiv notificationIcon">
                            <IoMdNotificationsOutline size={20} color='#F4145C' />
                        </div>
                        <div className="UserInfo">
                            <div className="userLogoImgDiv">
                                <div>
                                    <img src={userData.photoURL || UserLogo} alt="User Logo" className='userLogoImg' />
                                </div>
                                <div className='usernameAndEmail'>
                                    <span className='user-name'>{userData.displayName}</span>
                                    <p className='user-email margin-bottom-0'>{userData.email}</p>
                                </div>
                                <div className="downArrowIcon">
                                    <IoIosArrowDown />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Header
